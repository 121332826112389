import * as React from 'react';
import { sx } from '@lcifr/sx';
import { Heading } from '@lcifr/components/heading';
import { usePortableNodes } from '@lcifr/components/portable-nodes';
import { Stack } from '@lcifr/components/stack';
import { LinkStudio } from '@lcifr/components/link';
import { Button } from '@lcifr/components/button';
import { Box } from '@lcifr/components/box';
import { CollectionType } from '@lcifr/components/collection';
import { Image } from '@lcifr/components/image';
import { Inline } from '@lcifr/components/inline';

export const Headline = sx(Heading)({});

type MissionSectionProps = {
  content: any;
  link: any;
  linkText: any;
  personList: any;
  stackSpace: any;
};

export function MissionSection({
  content,
  link,
  linkText,
  personList,
  stackSpace,
}: MissionSectionProps) {
  const PortableNodes = usePortableNodes();
  return (
    <Stack space={stackSpace}>
      <CollectionType node={personList}>
        {(persons) => (
          <Box sx={{ display: 'flex' }}>
            {persons.map((person) =>
              (person as any)?.portrait ? (
                <Box
                  key={(person as any)?._id}
                  sx={{
                    position: 'relative',
                    width: [112, 112, 144],
                    height: [112, 112, 144],
                    borderRadius: '100%',
                    overflow: 'hidden',
                    ml: '-12px',
                    '&:first-of-type': {
                      ml: 0,
                    },
                  }}
                >
                  <Image
                    image={(person as any)?.portrait}
                    alt={(person as any)?.name}
                    sizes="144px"
                  />
                </Box>
              ) : null,
            )}
          </Box>
        )}
      </CollectionType>
      {content?.length && (
        <PortableNodes blocks={content}>
          {(children) => <Stack space={stackSpace}>{children}</Stack>}
        </PortableNodes>
      )}
      {link && linkText && (
        <Inline>
          <LinkStudio link={link}>
            <Button>{linkText}</Button>
          </LinkStudio>
        </Inline>
      )}
    </Stack>
  );
}
