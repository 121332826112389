import * as portable from '@lcifr/portable';
import { TextMediaSection } from 'components/TextMediaSection';

export const PortableTextMediaSectionType = portable.createType(
  'textMediaSection',
  ({ node, context }) => {
    return (
      <TextMediaSection
        width={node?.section?.width || context.defaultWrapWidth}
        minHeight={node?.section?.minHeight}
        bg={node?.section?.bg}
        bgImage={node?.section?.bgImage}
        spaceT={node?.section?.spaceT}
        spaceB={node?.section?.spaceB}
        stackSpace={node?.section?.stackSpace || 5}
        id={node?.section?.htmlID?.current}
        vRhythm={node?.section?.disableVRhythm ? false : true}
        content={node?.section?.blocks}
        image={node?.image}
        isReversed={node?.isReversed}
        isBoxed={node?.isBoxed}
      />
    );
  },
);
