import * as React from 'react';
import { Section } from '@lcifr/components/section';
import { Image } from 'components/Image';
import { useScrolledIntoView } from 'utils/hooks/useScrolledIntoView';
import { Box } from '@lcifr/components/box';

type ImpressionSingleProps = {
  image: any;
  stackSpace: any;
  width: any;
  minHeight: any;
  bg: any;
  bgImage: any;
  spaceT: any;
  spaceB: any;
  id: any;
  vRhythm: any;
};

export function ImpressionSingle({
  image,
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
}: ImpressionSingleProps) {
  const ref = React.useRef<Element>();

  const [didScrollIntoView] = useScrolledIntoView({
    retrigger: false,
    target: ref,
    threshold: 0.6,
  });

  return (
    <Section
      width={width || 'full'}
      minHeight={minHeight}
      bg={bg}
      bgImage={bgImage}
      spaceT={spaceT}
      spaceB={spaceB}
      stackSpace={stackSpace}
      id={id}
      vRhythm={vRhythm}
    >
      <Box ref={ref}>
        <Image
          image={image}
          alt=""
          sx={{
            position: 'relative',
            paddingTop: ['150%', '150%', `${(9 / 16) * 100}%`],
            transform: didScrollIntoView ? 'scale(1)' : 'scale(0.68)',
            margin: '0 auto',
            transformOrigin: 'top center',
            transition: 'all ease-out 0.4s',
          }}
        />
      </Box>
    </Section>
  );
}
