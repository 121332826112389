import * as portable from '@lcifr/portable';
import { ProductFocusList } from './ProductFocusList';

export const PortableProductFocusListType = portable.createType(
  'productFocusList',
  ({ node, context }) => {
    return (
      <ProductFocusList
        width={node?.section?.width || context.defaultWrapWidth}
        minHeight={node?.section?.minHeight}
        bg={node?.section?.bg}
        bgImage={node?.section?.bgImage}
        spaceT={node?.section?.spaceT || 8}
        spaceB={node?.section?.spaceB || 8}
        stackSpace={node?.section?.stackSpace}
        id={node?.section?.htmlID?.current}
        vRhythm={node?.section?.disableVRhythm ? false : true}
        content={node?.section?.blocks}
        productList={node?.productFocusList}
      />
    );
  },
);
