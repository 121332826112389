import * as React from 'react';
import { sx } from '@lcifr/sx';
import { Section } from '@lcifr/components/section';
import { Box } from '@lcifr/components/box';
import { Globe } from 'components/Globe';
import { Stack } from '@lcifr/components/stack';
import { CenterY } from '@lcifr/components/center';
import { useCollection, useRawI18n, useRawLocaleStrings } from '@lcifr/pages';
import { usePortableNodes } from '@lcifr/components/portable-nodes';
import { GlobeProps } from 'components/Globe/Globe';
import { Image } from 'components/Image';
import { LinkStudio } from '@lcifr/components/link';
import { Anchor } from '@lcifr/components/anchor';

type GlobeSectionProps = {
  stackSpace: any;
  width: any;
  minHeight: any;
  bg: any;
  bgImage: any;
  spaceT: any;
  spaceB: any;
  id: any;
  vRhythm: any;
  variant: any;
};

interface RawLocation {
  _type: 'location';
  country?: string;
  geolocation?: {
    _type: 'geopoint';
    lat: number;
    lng: number;
  };
}

export function GlobeSection({
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
  variant,
}: GlobeSectionProps) {
  let Content;
  const PortableNodes = usePortableNodes();
  const i18n = useRawI18n();
  const globeKpi = i18n?.globeKpi?.content;
  const globePartner = i18n?.globePartner;

  const salespartnerList = useCollection(
    i18n?.salespartnerSection?.salespartnerList,
  );

  const localeStrings = useRawLocaleStrings();

  const locations = React.useMemo(() => {
    const locations: RawLocation[] = [];

    (salespartnerList as unknown as { jurisdication: RawLocation[] }[]).forEach(
      ({ jurisdication }) => {
        if (jurisdication) {
          locations.push(...jurisdication);
        }
      },
    );

    return locations
      .map((location) => ({
        lat: location.geolocation?.lat,
        lng: location.geolocation?.lng,
        country: location.country,
      }))
      .filter(
        ({ lat, lng, country }) => lat && lng && country,
      ) as GlobeProps['locations'];
  }, [salespartnerList]);

  switch (variant) {
    default:
    case 'globeKpi':
      // eslint-disable-next-line react/display-name
      Content = () =>
        globeKpi?.length ? (
          <Box
            sx={{
              h2: {
                fontSize: ['18px', '24px', '32px'],
                lineHeight: ['24px', '28px', '38px'],
                fontWeight: 500,
                letterSpacing: '-0.72px',
              },
            }}
          >
            <PortableNodes blocks={globeKpi}>
              {(children) => <Stack space={stackSpace}>{children}</Stack>}
            </PortableNodes>
          </Box>
        ) : (
          <></>
        );
      break;

    case 'globePartner':
      // eslint-disable-next-line react/display-name
      Content = () =>
        globePartner?.content?.length ? (
          <Box>
            <PortableNodes blocks={globePartner?.content}>
              {(children) => <Stack space={stackSpace}>{children}</Stack>}
            </PortableNodes>

            {globePartner?.partners && (
              <Box
                sx={{
                  display: 'flex',
                  gap: '20px',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-start',
                }}
              >
                {globePartner?.partners?.map((partner, i) => (
                  <LinkStudio key={`partner-${i}`} link={partner?.link}>
                    <Anchor
                      sx={{
                        position: 'relative',
                        pt: '30%',
                        width: 'calc(50% - 10px)',
                      }}
                    >
                      <Image
                        image={partner?.image}
                        alt={partner?.linkText}
                        sx={{
                          img: {
                            objectFit: 'contain !important',
                          },
                        }}
                      />
                    </Anchor>
                  </LinkStudio>
                ))}
              </Box>
            )}
          </Box>
        ) : (
          <></>
        );
      break;
  }

  return (
    <Box sx={{ maxWidth: '100vw', overflow: 'hidden' }}>
      <Section
        width={width}
        minHeight={minHeight}
        bg={bg}
        bgImage={bgImage}
        spaceT={spaceT}
        spaceB={spaceB}
        stackSpace={stackSpace}
        id={id}
        vRhythm={vRhythm}
      >
        <Box sx={{ display: 'grid' }}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr', '40% 60%'],
              gap: 40,
              pt: ['8px', '8px', 40],
              width: ['100%', '100%', 'calc(100% - 40px)'],
            }}
          >
            <CenterY>
              <Content />
            </CenterY>
            <Box
              sx={{ order: [-1, -1, 1], display: 'grid', placeItems: 'center' }}
            >
              <Globe locations={locations} />
            </Box>
          </Box>
        </Box>
      </Section>
    </Box>
  );
}
