import * as portable from '@lcifr/portable';
import { ProductTechnologyList } from './ProductTechnologyList';

export const PortableProductTechnologyListType = portable.createType(
  'productTechnologyList',
  ({ node, context }) => {
    return (
      <ProductTechnologyList
        width={node?.section?.width || context.defaultWrapWidth}
        minHeight={node?.section?.minHeight}
        bg={node?.section?.bg}
        bgImage={node?.section?.bgImage}
        spaceT={node?.section?.spaceT || 8}
        spaceB={node?.section?.spaceB || 8}
        stackSpace={node?.section?.stackSpace || 1}
        id={node?.section?.htmlID?.current}
        vRhythm={node?.section?.disableVRhythm ? false : true}
        productTechnologies={node?.productTechnologies}
      />
    );
  },
);
