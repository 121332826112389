import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

const AllVideosReadyContext = createContext<{
  allVideosReady: boolean;
  registerVideo: (videoId: string) => void;
  setVideoReady: (videoId: string) => void;
}>({
  allVideosReady: false,
  registerVideo: () => {},
  setVideoReady: () => {},
});

export const AllVideosReadyProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [videos, setVideos] = useState<Record<string, boolean>>({});

  const registerVideo = useCallback((videoId: string) => {
    setVideos((videos) => ({
      ...videos,
      [videoId]: false,
    }));
  }, []);

  const setVideoReady = useCallback((videoId: string) => {
    setVideos((videos) => ({
      ...videos,
      [videoId]: true,
    }));
  }, []);

  const allVideosReady = useMemo(() => {
    return Object.values(videos).length
      ? Object.values(videos).every(Boolean)
      : false;
  }, [videos]);

  return (
    <AllVideosReadyContext.Provider
      value={useMemo(
        () => ({
          registerVideo,
          setVideoReady,
          allVideosReady,
        }),
        [registerVideo, setVideoReady, allVideosReady],
      )}
    >
      {children}
    </AllVideosReadyContext.Provider>
  );
};

export const useAllVideosReady = () => {
  return useContext(AllVideosReadyContext);
};
