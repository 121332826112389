import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { sx } from '@lcifr/sx';
import { Stack } from '@lcifr/components/stack';
import { Button } from '@lcifr/components/button';
import { Icon as LuciferIcon } from '@lcifr/components/icon';
import { LinkStudio } from '@lcifr/components/link';
import { Image } from 'components/Image';
import { Inline } from '@lcifr/components/inline';

export const TileButtonNews = sx(Button)({
  transition: 'all 0.2s ease-out',
  p: ['8px 24px', '14px 36px', '14px 36px'],
});

export const Tile = sx(Box)({
  display: 'block',
  position: 'relative',
  bg: '#F8F7FD',
  pt: '75%',
  color: 'text',
  overflow: 'hidden',
  cursor: 'pointer',
  '.TileButtonNews.TileButtonNews': {
    transition: 'all 0.2s ease-out',
    p: ['8px 24px', '14px 36px', '14px 36px'],
    pointerEvents: 'none',
  },
  '&:hover': {
    '.TileContent': {
      transform: 'scale(0.96)',
    },
    '.TileCard': {
      transform: 'scale(1.06)',
    },
    '.TileCardImage': {
      transform: 'scale(1.02)',
    },
    '.TileButtonNews': {
      bg: 'text',
      borderColor: 'text',
      color: 'text.inverted',
      '&:hover': {
        transform: 'initial',
      },
    },
  },
});

export const Content = sx(Stack)({
  position: 'absolute',
  top: '15%',
  left: '10%',
  transition: 'transform 0.3s ease-out',
  transformOrigin: 'top left',
  zIndex: 1,
});

export const Cards = sx(Box)({
  position: 'absolute',
  bottom: '10%',
  right: '-20%',
  height: '60%',
  width: '75%',
  display: 'flex',
});

export const Card = sx(Box)({
  position: 'relative',
  height: '100%',
  width: '100%',
  marginRight: '5%',
  bg: 'background',
  boxShadow: '4px 4px 4px rgba(0,0,0,0.02)',
  borderRadius: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.3s ease-out',
  transformOrigin: 'center right',
});

export const CardImage = sx(Image)({
  position: 'relative',
  height: '100%',
  width: '100%',
  boxShadow: '4px 4px 4px rgba(0,0,0,0.02)',
  borderRadius: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.3s ease-out',
  transformOrigin: 'center right',
  // @ts-ignore
  '*': {
    borderRadius: 20,
    display: 'block !important',
    position: 'absolute !important',
    width: '100% !important',
    height: '100% !important',
    objectFit: 'cover',
  },
});

export const Icon = sx(LuciferIcon)({
  width: '80%',
  height: '80%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    height: 'inherit',
    width: 'inherit',
  },
});

type TeaserNewsProps = {
  headline: string;
  link: any;
  linkText: string;
  image: any;
};

export function TeaserNews({
  headline,
  link,
  linkText,
  image,
}: TeaserNewsProps) {
  return (
    <LinkStudio link={link}>
      <Tile>
        <Content space={[2, 2, 5]} className="TileContent">
          {headline && (
            <Box
              sx={{
                fontSize: [32, 32, 42],
                lineHeight: '44px',
                fontWeight: 500,
                letterSpacing: '-2px',
                maxWidth: 240,
              }}
            >
              {headline}
            </Box>
          )}
          {linkText && (
            <Inline>
              <TileButtonNews className="TileButtonNews" variant="ghostDark">
                {linkText}
              </TileButtonNews>
            </Inline>
          )}
        </Content>
        <Cards>
          <Card className="TileCard">
            <Icon name="logo" />
          </Card>
          <CardImage
            className="TileCardImage"
            image={image}
            alt="Teaser Visualisation"
          />
        </Cards>
      </Tile>
    </LinkStudio>
  );
}
