import { Theme, ifReadable, alpha } from '@lcifr/theming';
import icons from './icons';

const myTheme: Theme = {
  /**
   * Use the studio to define your design tokens there.
   * This theme and the token dictionary are merged in pages/_app.tsx
   */
  styles: {
    body: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 200,
      minHeight: '100vh',
      fontSize: 'medium',
      backgroundColor: 'background',
      maxWidth: '100vw',
      overflowX: 'hidden',
      textDecorationThickness: 'from-font',
      textDecorationWidth: 'from-font',
      textDecorationSkipInk: 'all',
      textUnderlineOffset: '0.02em',
      q: {
        '&::before': {
          content: '"» "',
          opacity: 0.5,
        },
        '&::after': {
          content: '" «"',
          opacity: 0.5,
        },
      },
    },
    img: {
      verticalAlign: 'bottom',
    },
    // select: {
    //   fontSize: '15px',
    //   fontWeight: 300,
    //   fontFamily: 'body',
    // },
    // input: {
    //   fontSize: '15px',
    //   fontWeight: 300,
    //   fontFamily: 'body',
    // },
    focusRing: {
      outlineWidth: 2,
      outlineStyle: 'solid',
      outlineColor: ifReadable('primary', 'currentColor'),
      outlineOffset: 2,
    },
    Text: {
      default: {
        fontSize: ['15px'],
        lineHeight: ['24px'],
      },
      bleach: {
        color: 'bleach',
      },
      error: {
        color: '#ff2f36',
      },
    },
    Blockquote: {
      default: {
        '&&': {
          borderLeft: 0,
          bg: 'quaternary',
          py: 3,
          px: 6,
          borderRadius: 6,
          color: 'primary',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '-0.48px',
        },
      },
    },
    Heading: {
      any: {
        lineHeight: 'heading',
        hyphens: ['auto', null, 'none'],
      },
      lead: {
        fontSize: ['72px'],
        lineHeight: ['74px'],
        fontWeight: 500,
        letterSpacing: '-0.72px',
      },
      h1: {
        fontSize: ['32px', '32px', '48px'],
        lineHeight: ['36px', '36px', '54px'],
        fontWeight: 500,
        letterSpacing: '-1.44px',
      },
      h2: {
        fontSize: ['24px', '28px', '42px'],
        lineHeight: ['28px', '32px', '44px'],
        fontWeight: 500,
        letterSpacing: '-0.03em',
      },
      h3: {
        fontSize: ['18px', '24px', '32px'],
        lineHeight: ['24px', '28px', '38px'],
        fontWeight: 500,
        letterSpacing: '-0.72px',
      },
      h4: {
        fontSize: ['16px', '16px', '24px'],
        lineHeight: ['20px', '20px', '32px'],
        letterSpacing: ['-0.48px', '-0.48px', '-0.72px'],
        fontWeight: 500,
      },
      h5: {
        fontSize: ['16px', '16px', '18px'],
        lineHeight: ['20px', '20px', '24px'],
        letterSpacing: ['-0.48px', '-0.48px', '-0.72px'],
        fontWeight: 500,
      },
      h6: {
        fontSize: ['15px', '15px', '15px'],
        lineHeight: ['20px', '20px', '20px'],
        fontWeight: 500,
      },
    },
    Anchor: {
      any: {
        color: 'inherit',
        textDecorationLine: 'none',
        '&&[data-is-focus-visible="true"]': {
          outline: 'none',
          textDecorationLine: 'underline',
        },
        '&[href]': {
          cursor: 'pointer',
        },
      },
      text: {
        fontSize: '13px',
        fontWeight: 600,
        textDecoration: 'underline',
        color: 'inherit',
      },
      footerHeading: {
        fontSize: '17px',
        fontWeight: 500,
        color: 'inherit',
      },
      footerLegalLink: {
        fontSize: '13px',
        fontWeight: 600,
        color: 'inherit',
      },
      download: {
        fontSize: ['13px'],
        fontWeight: 500,
        color: 'primary',
        textDecoration: 'underline',
      },
      default: {},
      navItem: {
        fontSize: ['13px'],
        fontWeight: 600,
        transition: 'color 0.3s ease-out',
        '&:hover': {
          color: 'primary',
        },
      },
      mobileNavItem: {
        fontSize: ['24px', '28px'],
        fontWeight: 500,
        color: 'inherit',
        display: 'inline-block',
        width: '100%',
      },
      buttonSmallPrimary: {
        touchAction: 'manipulation',
        position: 'relative',
        zIndex: 1,
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: 100,
        color: 'text.inverted',
        fontWeight: 600,
        bg: 'primary',
        padding: '8px 32px',
        fontSize: '12px',
        whiteSpace: 'nowrap',
        transition: 'transform ease-out 0.3s',
        '&:hover': {
          transform: 'scale(1.04)',
        },
      },
      buttonGhostDark: {
        touchAction: 'manipulation',
        position: 'relative',
        zIndex: 1,
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: 100,
        color: 'text',
        fontWeight: 600,
        bg: 'transparent',
        border: '2px solid',
        borderColor: alpha('text', 0.2),
        padding: '14px 36px',
        fontSize: '13px',
        transition: 'transform ease-out 0.3s',
        '&:hover': {
          transform: 'scale(1.04)',
        },
      },
    },
    Button: {
      any: {
        touchAction: 'manipulation',
        position: 'relative',
        zIndex: 1,
        borderRadius: 100,
        cursor: 'pointer',
        transition: 'transform ease-out 0.3s',
        '&:hover': {
          transform: 'scale(1.04)',
        },
      },
      default: {
        '&&': {
          color: 'text.inverted',
          bg: 'primary',
          border: '2px solid',
          borderColor: 'primary',
          padding: '14px 36px',
          fontWeight: 600,
          fontSize: '13px',
        },
      },
      dark: {
        '&&': {
          color: 'text.inverted',
          bg: 'text',
          padding: '14px 36px',
          fontWeight: 600,
          fontSize: '13px',
        },
      },
      small: {
        '&&': {
          color: 'text.inverted',
          bg: 'primary',
          border: '2px solid',
          borderColor: 'primary',
          padding: '8px 32px',
          fontSize: '12px',
          fontWeight: 600,
          whiteSpace: 'nowrap',
        },
      },
      bright: {
        '&&': {
          color: 'text',
          bg: 'background',
          padding: '14px 36px',
          border: '2px solid',
          borderColor: 'transparent',
          borderRadius: 100,
          fontWeight: 600,
          fontSize: '13px',
          boxShadow: '4px 4px 4px rgba(15, 17, 37, 0.02)',
          '&:hover': {
            bg: 'background',
            color: 'text',
          },
        },
      },
      ghostPrimary: {
        '&&': {
          color: 'primary',
          bg: 'transparent',
          padding: '14px 36px',
          border: '2px solid',
          borderRadius: 100,
          borderColor: alpha('primary', 0.2),
          fontWeight: 600,
          fontSize: '13px',
          '&:hover': {
            bg: 'primary',
            borderColor: 'primary',
            color: 'text.inverted',
          },
        },
      },
      ghostDark: {
        '&&': {
          color: 'text',
          bg: 'transparent',
          padding: '14px 36px',
          border: '2px solid',
          borderRadius: 100,
          borderColor: alpha('text', 0.2),
          fontWeight: 600,
          fontSize: '13px',
          // '&:hover': {
          //   bg: 'background',
          //   borderColor: 'background',
          //   color: 'text',
          // },
        },
      },
      ghostBright: {
        '&&': {
          color: 'text.inverted',
          bg: 'transparent',
          padding: '14px 36px',
          border: '2px solid',
          borderRadius: 100,
          borderColor: alpha('text.inverted', 0.2),
          fontWeight: 600,
          fontSize: '13px',
          '&:hover': {
            bg: 'background',
            borderColor: 'background',
            color: 'text',
          },
        },
      },
      stripped: {
        '&&': {
          padding: 0,
          color: 'text',
          bg: 'transparent',
          fontWeight: 600,
          fontSize: '13px',
          display: 'inline-flex',
          alignItems: 'center',
        },
      },
      strippedPrimary: {
        '&&': {
          padding: 0,
          color: 'primary',
          bg: 'transparent',
          fontWeight: 600,
          fontSize: '13px',
          display: 'inline-flex',
          alignItems: 'center',
        },
      },
    },
  },
  minimumReadableContrast: 4.5,
  components: {
    icons,
    cards: {},
  },
  fontFaces: [
    {
      family: 'Poppins',
      extensions: ['woff2', 'woff'],
      display: 'swap',
      formats: [
        {
          url: '/fonts/poppins-v20-latin-ext_latin/poppins-v20-latin-ext_latin-300',
          weight: 300,
        },
        {
          url: '/fonts/poppins-v20-latin-ext_latin/poppins-v20-latin-ext_latin-regular',
          weight: 400,
        },
        {
          url: '/fonts/poppins-v20-latin-ext_latin/poppins-v20-latin-ext_latin-500',
          weight: 500,
        },
        {
          url: '/fonts/poppins-v20-latin-ext_latin/poppins-v20-latin-ext_latin-600',
          weight: 600,
        },
      ],
    },
  ],
};

export default myTheme;
