import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { sx } from '@lcifr/sx';
import { Wrap as LuciferWrap } from '@lcifr/components/wrap';
import { Icon as LuciferIcon } from '@lcifr/components/icon';
import { usePortableNodes } from '@lcifr/components/portable-nodes';
import { Stack } from '@lcifr/components/stack';
import { useRawI18n } from '@lcifr/pages';
import { Heading } from '@lcifr/components/heading';
import { Text } from '@lcifr/components/text';
import { Flag } from 'components/Flag';
import { Button } from '@lcifr/components/button';
import { LinkStudio } from '@lcifr/components/link';
import { Anchor as LuciferAnchor } from '@lcifr/components/anchor';
import { alpha } from '@lcifr/theming';
import { useLocalStorage } from 'utils/hooks/useLocaleStorage';
import { CollectionType } from '@lcifr/components/collection';
import { useRouter } from 'next/router';

export const Wrap = sx(LuciferWrap)({
  paddingLeft: '0 !important',
  paddingRight: '0 !important',
});

export const Icon = sx(LuciferIcon)({
  position: 'relative',
  flexShrink: 0,
  width: 32,
  height: 32,
  svg: { width: '100%', height: '100%' },
  color: 'text.inverted',
});

export const Anchor = sx(LuciferAnchor)({
  position: 'relative',
  flexShrink: 0,
  pr: '12px',
  mr: '12px',
  '&:last-of-type': {
    pr: 0,
    mr: 0,
    '&:after': {
      display: 'none',
    },
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: '1px',
    height: '12px',
    bg: alpha('text.inverted', 0.2),
  },
});

export const GradientBox = sx(Box)({
  display: ['none', 'none', 'block'],
  position: 'absolute',
  top: 0,
  left: 0,
  width: '50%',
  height: '100%',
  background:
    'linear-gradient(180deg, rgba(15, 17, 37, 0.1) 0%, rgba(15, 17, 37, 0.03) 100%)',
});

export const ColorBox = sx(Box)({
  display: ['none', 'none', 'block'],
  position: 'absolute',
  bottom: 0,
  right: 0,
  width: '50%',
  height: '100%',
  bg: 'text',
});

export const Contact = sx(Box)({
  display: 'flex',
  flexDirection: ['column', 'column', 'row'],
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  py: [0, 0, 96],
});

export const ContactInfo = sx(Box)({
  display: 'flex',
  px: ['16px', '16px', 0],
  py: ['40px', '40px', 0],
  width: ['100%', '100%', '46%'],
  flexShrink: 0,
  p: {
    a: {
      fontSize: '13px',
      fontWeight: 600,
      textDecoration: 'underline',
      marginBottom: 2,
    },
  },
  background: [
    'linear-gradient(180deg, rgba(15, 17, 37, 0.1) 0%, rgba(15, 17, 37, 0.03) 100%)',
    'linear-gradient(180deg, rgba(15, 17, 37, 0.1) 0%, rgba(15, 17, 37, 0.03) 100%)',
    'none',
  ],
});

export const ContactSales = sx(Box)({
  color: 'background',
  px: ['16px', '16px', 0],
  py: ['40px', '40px', 0],
  width: ['100%', '100%', '45%'],
  bg: ['text', 'text', 'none'],
});

type SalespartnerProps = {};

export function SalespartnerTeaser({}: SalespartnerProps) {
  const [detectedCountry, setDetectedCountry] = React.useState('US');
  const [trackingEnabled, setTrackingEnabled] = useLocalStorage(
    'trackingEnabled',
    false,
  );

  React.useEffect(() => {
    if (trackingEnabled) {
      navigator.geolocation.getCurrentPosition(async (cb) => {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${cb.coords.latitude}&lon=${cb.coords.longitude}&zoom=3`,
        );
        const data = await response.json();
        setDetectedCountry(data.address.country_code);
      });
    }
  }, [trackingEnabled]);

  const i18n = useRawI18n();
  const salesLink = i18n?.contactSales?.link;
  const salesLinkText = i18n?.contactSales?.linkText;
  const salesHeadline = i18n?.contactSales?.headline;
  const salesGeoLinkText = i18n?.contactSales?.geoLinkText;
  const salesPartnerList = i18n?.contactSales?.salesPartnerList;
  const { locale } = useRouter();

  return (
    <ContactSales>
      <Stack space={7}>
        <Stack space={5}>
          {salesHeadline && <Heading variant="h4">{salesHeadline}</Heading>}

          <CollectionType node={salesPartnerList}>
            {(salesPartner) => {
              const currentLocale = locale?.toUpperCase();

              const partner = (salesPartner as any).find((partner) => {
                return partner.jurisdication?.some(
                  (location) => location.country === currentLocale,
                );
              });

              const defaultPartner = (salesPartner as any).find((partner) => {
                return partner.jurisdication?.some(
                  // DE means it defaults to CETOTEC ~DH
                  (location) => location.country === 'DE',
                );
              });

              const currentPartner = partner ?? defaultPartner;

              return (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Flag
                      code={currentPartner?.jurisdication?.[0]?.country}
                      sx={{ pr: 3 }}
                    />
                    <Heading variant="h3">
                      <LinkStudio
                        link={{
                          ...salesLink,
                          searchHash: `#${currentPartner?.id}`,
                        }}
                      >
                        <Anchor>{currentPartner?.partner}</Anchor>
                      </LinkStudio>
                    </Heading>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    {currentPartner?.phoneLink && (
                      <LinkStudio link={currentPartner?.phoneLink}>
                        <Anchor variant="text" aria-label="Phone">
                          <Icon name="phone" sx={{ width: 30, height: 30 }} />
                        </Anchor>
                      </LinkStudio>
                    )}
                    {currentPartner?.mailLink && (
                      <LinkStudio link={currentPartner?.mailLink}>
                        <Anchor variant="text" aria-label="E-Mail">
                          <Icon name="envelope" sx={{ mt: '-3px' }} />
                        </Anchor>
                      </LinkStudio>
                    )}
                    <LinkStudio link={salesLink}>
                      <Anchor variant="text" aria-label="Salespartner Details">
                        <Icon name="popup" />
                      </Anchor>
                    </LinkStudio>
                  </Box>
                </Box>
              );
            }}
          </CollectionType>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'baseline',
            flexWrap: 'wrap',
            rowGap: '16px',
          }}
        >
          {salesGeoLinkText && !trackingEnabled && (
            <Button
              variant="small"
              onClick={() => setTrackingEnabled(true)}
              sx={{
                marginRight: [null, null, 4],
                whiteSpace: 'normal!important',
              }}
            >
              {salesGeoLinkText}
            </Button>
          )}

          {salesLinkText && salesLink ? (
            <Text sx={{ whiteSpace: 'nowrap', color: 'text.inverted' }}>
              <LinkStudio link={salesLink}>
                <Anchor variant="text">{salesLinkText}</Anchor>
              </LinkStudio>
            </Text>
          ) : null}
        </Box>
      </Stack>
    </ContactSales>
  );
}
