import * as React from 'react';
import { useEffect } from 'react';
import { useRawOtherDocument } from '@lcifr/pages';
import { MuxPlayer } from 'components/MuxPlayer';
import { CSSProperties } from 'react';
import { sx } from '@lcifr/sx';
import { useAllVideosReady } from 'components/VideoList/VideoList.context';
import { keyframes } from '@emotion/react';

type VideoProps = {
  videoAssetId: string;
  showControls?: boolean;
  className?: string;
  loop?: boolean;
  muted?: boolean;
  autoplay?: boolean;
  style?: CSSProperties;
  sx?: any;
  autoload?: boolean;
  poster?: string | boolean;
  iterator?: string;
};

const loadingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledMuxPlayer = sx(MuxPlayer)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  '& > div': { height: '100%' },
  '& video': {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    opacity: 0,
    transition: 'opacity 0.33s',
  },
  '&::before': {
    content: "''",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-30px',
    marginLeft: '-30px',
    width: '50px',
    height: '50px',
    borderRadius: '50px',
    border: '5px solid rgba(0, 71, 255, 0.2)',
    borderTopColor: 'primary',
    animation: loadingAnimation + ' 1.35s linear infinite',
    opacity: 1,
    transition: 'opacity 0.33s',
  },
  '&.is-ready::before': {
    opacity: 0,
  },
  '&.is-ready video': {
    opacity: 1,
  },
});

export const Video = React.forwardRef(function Video(
  {
    videoAssetId,
    showControls,
    className,
    loop,
    muted,
    autoplay,
    style,
    sx,
    autoload,
    poster,
    iterator,
  }: VideoProps,
  ref,
) {
  const [isReady, setIsReady] = React.useState(false);
  const assetDocument = useRawOtherDocument(videoAssetId);
  const { registerVideo, setVideoReady } = useAllVideosReady();
  const videoId = iterator;

  useEffect(() => {
    if (videoId) {
      registerVideo(videoId);
    }
  }, [registerVideo, videoId]);

  return assetDocument ? (
    <StyledMuxPlayer
      ref={ref}
      assetDocument={assetDocument}
      showControls={showControls}
      className={className + (isReady ? ' is-ready' : '')}
      sx={sx}
      loop={loop}
      muted={muted}
      autoplay={autoplay}
      style={style}
      disablePictureInPicture
      autoload={autoload}
      poster={poster}
      iterator={iterator}
      isAttached={() => {
        setIsReady(true);
        if (videoId) {
          setVideoReady(videoId);
        }
      }}
    />
  ) : null;
});
