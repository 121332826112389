import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box } from '@lcifr/components/box';
import { Wrap as LuciferWrap } from '@lcifr/components/wrap';
import { sx } from '@lcifr/sx';
import { Section } from '@lcifr/components/section';
import { Text } from '@lcifr/components/text';
import { Stack } from '@lcifr/components/stack';
import { Icon as IconLucifer } from '@lcifr/components/icon';
import { Image } from 'components/Image';
import { Video } from 'components/Video';
import { VideoList } from 'components/VideoList';
import 'swiper/css';

export const Around = sx(Box)(({ spaceT, spaceB }) => ({
  mt: spaceT,
  mb: spaceB,
}));

export const Bg = sx(Box)({
  position: 'relative',
  pb: [8],
  mb: [8],
  pl: [0, 0, 40],
  pr: [0, 0, 40],
  '.heroProcess': {
    pl: '0 !important',
    pr: '0 !important',
  },
  background: '#F7F7F9',
  // 'linear-gradient(180deg, rgba(15, 17, 37, 0.1) 0%, rgba(15, 17, 37, 0.03) 100%)',
});

export const Stage = sx(Box)({
  '.swiper': {
    overflow: 'hidden',
  },
  '.swiper-wrapper': {
    display: [undefined, undefined, 'grid'],
    gridAutoColumns: [undefined, undefined, '1fr'],
    gridAutoFlow: [undefined, undefined, 'column'],
    alignItems: 'start',
    gap: [undefined, undefined, 20],
    pt: ['56px', '56px', '140px'],
    minHeight: '30vh',
  },
  '.swiper-slide': {
    position: 'relative',
    width: [undefined, undefined, 'auto !important'],
  },
});

export const Item = sx(Stack)({
  width: '100%',
});

export const Icon = sx(IconLucifer)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '0.2s opacity ease-out',
  pointerEvents: 'auto',
  p: 3,
  borderRadius: '50%',
  svg: {
    width: '32px',
    height: '100%',
  },
});

export const Plate = sx(Box)({
  display: ['flex', 'flex', 'none'],
  userSelect: 'none',
  position: 'absolute',
  top: '12px',
  fontWeight: 600,
  fontSize: '12px',
  left: '50%',
  zIndex: 50,
  transform: 'translateX(-50%)',
  borderRadius: '50px',
  cursor: 'pointer',
  transition: '0.2s opacity ease-out',
  px: 4,
  py: 2,
  bg: 'background',
  boxSizing: 'border-box',
  boxShadow: '4px 4px 4px rgba(0,0,0,0.02)',
});

const handleScrollClick = (id: string) => {
  /**
   * using scrollIntoView instead of css:`scroll-behaviour: smooth` because of
   * https://github.com/vercel/next.js/issues/12105#issuecomment-908317568
   */

  if (typeof document !== 'undefined') {
    const element = document.getElementById(id.replace('#', ''));
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
};

const scrollTop = () => {
  if (typeof window !== 'undefined') {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

type HeroProcessProps = {
  headline: any;
  image: any;
  width: any;
  minHeight: any;
  bg: any;
  bgImage: any;
  spaceT: any;
  spaceB: any;
  stackSpace: any;
  id: any;
  vRhythm: any;
  processItems: any;
};

export function HeroProcess({
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
  processItems,
}: HeroProcessProps) {
  const [swipe, setSwipe] = React.useState<any>();
  const [activeProcess, setActiveProcess] = React.useState<number>(0);
  const [realIndex, setRealIndex] = React.useState<number>(0);
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const [activeBreakpoint, setActiveBreakpoint] = React.useState<string>('0');

  return (
    <Around spaceT={spaceT} spaceB={spaceB}>
      <Bg>
        <Section
          className="heroProcess"
          width={'full'}
          minHeight={minHeight}
          bg={bg}
          bgImage={bgImage}
          spaceT={undefined}
          spaceB={undefined}
          stackSpace={stackSpace}
          id={id}
          vRhythm={vRhythm}
        >
          <LuciferWrap width="full">
            {processItems?.map((processItem, i) =>
              activeProcess === i ? (
                <Stage key={`processItem-${i}`}>
                  <VideoList>
                    <Swiper
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                          allowTouchMove: true,
                        },
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                          allowTouchMove: true,
                        },
                        961: {
                          allowTouchMove: false,
                        },
                      }}
                      onBeforeInit={(swiper) => {
                        setSwipe(swiper);
                      }}
                      onInit={() => {
                        setActiveIndex(processItems?.[i]?.heroItems?.length);
                      }}
                      onBreakpoint={(swiper) => {
                        setSwipe(swiper);
                        swiper.slideTo(0);
                        setActiveBreakpoint(swiper?.currentBreakpoint);
                        setActiveIndex(processItems?.[i]?.heroItems?.length);
                      }}
                      onSlideChange={(swiper) => {
                        setRealIndex(swiper.realIndex);
                      }}
                    >
                      {processItem?.heroItems?.map((heroItem, j) => (
                        <SwiperSlide key={'item-' + j}>
                          <Item space={4}>
                            <Box
                              sx={{
                                pt: '150%',
                                position: 'relative',
                              }}
                            >
                              {(heroItem as any)?.isVideo ? (
                                <Video
                                  videoAssetId={
                                    (heroItem as any)?.video?.asset?._ref
                                  }
                                  showControls={false}
                                  loop
                                  muted
                                  autoplay
                                  sx={{ bg: '#F7F7F9 !important' }}
                                  autoload
                                  // poster={false}
                                  iterator={`${i}-${j}`}
                                />
                              ) : (heroItem as any)?.image ? (
                                <Image
                                  image={(heroItem as any)?.image}
                                  alt=""
                                  sx={{
                                    img: {
                                      userSelect: 'none',
                                      objectFit: 'contain!important',
                                    },
                                  }}
                                />
                              ) : null}
                            </Box>
                            {heroItem?.hash ? (
                              <Box
                                onClick={() => {
                                  handleScrollClick(`#${heroItem?.hash}`);
                                }}
                              >
                                <Stack space={2} sx={{ cursor: 'pointer' }}>
                                  <Text
                                    sx={{
                                      textAlign: 'center',
                                      display: 'block',
                                      fontSize: ['24px', '24px', '16px'],
                                      lineHeight: 1.25,
                                      fontWeight: 500,
                                      userSelect: 'none',
                                      color: 'primary',
                                    }}
                                  >
                                    {(heroItem as any)?.headline}
                                  </Text>
                                  <Icon
                                    sx={{
                                      margin: '0 auto',
                                      borderRadius: '100%',
                                      height: [36, 36, 18],
                                      width: [36, 36, 18],
                                      bg: 'background',
                                      display: 'inline-flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      svg: {
                                        flexShrink: 0,
                                        fill: 'primary',
                                        pt: 1,
                                        height: [28, 28, 14],
                                        width: [28, 28, 14],
                                      },
                                    }}
                                    name="chev-down"
                                  />
                                </Stack>
                              </Box>
                            ) : (
                              <Text
                                sx={{
                                  textAlign: 'center',
                                  display: 'block',
                                  fontSize: ['24px', '24px', '16px'],
                                  lineHeight: 1.25,
                                  fontWeight: 500,
                                  userSelect: 'none',
                                }}
                              >
                                {(heroItem as any)?.headline}
                              </Text>
                            )}
                          </Item>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </VideoList>
                  <Box
                    sx={{
                      position: 'absolute',
                      zIndex: 50,
                      pointerEvents: 'none',
                      px: '8px',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      height: 72,
                      display: ['flex', 'flex', 'none'],
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Icon
                      onClick={() => swipe.slidePrev()}
                      name="chev-left"
                      sx={{
                        cursor: realIndex !== 0 ? 'pointer' : 'normal',
                        opacity: realIndex !== 0 ? 1 : 0,
                        width: 72,
                        height: 72,
                        bg: '#fff',
                        boxShadow: '4px 4px 4px rgba(0,0,0,0.02)',
                      }}
                    />

                    <Icon
                      onClick={() => swipe.slideNext()}
                      name="chev-right"
                      sx={{
                        cursor:
                          realIndex + 1 !== activeIndex &&
                          activeBreakpoint === '0'
                            ? 'pointer'
                            : realIndex + 1 !== activeIndex - 1 &&
                              activeBreakpoint !== '0'
                            ? 'pointer'
                            : 'normal',
                        opacity:
                          realIndex + 1 !== activeIndex &&
                          activeBreakpoint === '0'
                            ? 1
                            : realIndex + 1 !== activeIndex - 1 &&
                              activeBreakpoint !== '0'
                            ? 1
                            : 0,
                        width: 72,
                        height: 72,
                        bg: '#fff',
                        boxShadow: '4px 4px 4px rgba(0,0,0,0.02)',
                      }}
                    />
                  </Box>
                  <Plate>
                    {activeBreakpoint === '0'
                      ? `${realIndex + 1} / ${activeIndex}`
                      : `${realIndex + 1} & ${realIndex + 2}`}
                  </Plate>
                </Stage>
              ) : null,
            )}
          </LuciferWrap>
        </Section>
        <Box
          sx={{
            overflow: 'hidden',
            position: 'absolute',
            width: '100%',
            height: 84,
            pb: '12px',
            bottom: 0,
            left: 0,
            zIndex: 50,
            transform: 'translate(0, 50%)',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: '50%',
              whiteSpace: 'nowrap',
              transform: 'translate(-50%)',
              display: 'flex',
              gap: '4px',
              flexWrap: 'nowrap',
              borderRadius: '100px',
              bg: 'background',
              boxShadow: '4px 4px 4px rgba(0,0,0,0.02)',
              userSelect: 'none',
            }}
          >
            {processItems?.map((processItem, i) => (
              <Box
                key={`nav-${i}`}
                sx={{
                  display: 'flex',
                  flexShrink: 0,
                  flexDirection: 'column',
                  borderRadius: '100px',
                  bg: activeProcess === i ? 'primary' : 'none',
                  color: activeProcess === i ? 'text.inverted' : 'text',

                  py: 3,
                  px: 5,
                  textAlign: 'center',
                  cursor: 'pointer',
                  transition: 'background ease-out 0.1s',
                }}
                onClick={() => {
                  setActiveProcess(i);
                  scrollTop();
                }}
              >
                {processItem?.name && (
                  <Box sx={{ fontSize: '14px', fontWeight: 600 }}>
                    {processItem?.name}
                  </Box>
                )}
                {processItem?.subline && (
                  <Box sx={{ fontSize: '12px', fontWeight: 400 }}>
                    {processItem?.subline}
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Bg>

      <Box
        sx={{
          textAlign: 'center',
          px: '8px',
          margin: '0 auto',
          maxWidth: ['100%', '100%', '60%'],
        }}
      >
        {processItems?.map(
          (processItem, i) =>
            activeProcess === i && (
              <Text
                key={`text-${i}`}
                sx={{
                  fontSize: [16, 16, 20],
                  lineHeight: 1.5,
                  userSelect: 'none',
                }}
              >
                {(processItem as any)?.text}
              </Text>
            ),
        )}
      </Box>
    </Around>
  );
}
