import * as React from 'react';
import { sx } from '@lcifr/sx';
import { Icon as LuciferIcon } from '@lcifr/components/icon';

export const MarkIconWrap = sx('span')(({ align, position }) => ({
  position: 'relative',
  display: 'inline-flex',
  justifyItems: 'center',
  alignItems: 'center',
  textAlign: (align as any) || 'left',
  flexDirection: ((position === 'top' && 'column') ||
    (position === 'bottom' && 'column-reverse') ||
    (position === 'left' && 'row') ||
    (position === 'right' && 'row-reverse')) as any,
}));

export const MarkIcon = sx(LuciferIcon)(({ width, height, position }) => ({
  position: 'relative',
  svg: {
    lineHeight: 'inherit',
  },
  paddingTop: (position as any) === 'bottom' ? 2 : 0,
  paddingRight: (position as any) === 'left' ? 2 : 0,
  paddingBottom: (position as any) === 'top' ? 2 : 0,
  paddingLeft: (position as any) === 'right' ? 2 : 0,
  color: 'text',
}));

export function Icon({ children, mark }) {
  return (
    <MarkIconWrap position={mark?.position} align={mark?.align}>
      <MarkIcon
        name={mark?.variant}
        position={mark?.position}
        align={mark?.align}
      />
      {children[0]}
    </MarkIconWrap>
  );
}
