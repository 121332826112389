import * as React from 'react';
import { Heading } from '@lcifr/components/heading';

export function Lead({ children }) {
  return (
    <Heading as="h1" variant="lead">
      {children}
    </Heading>
  );
}
