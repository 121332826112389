import { useRawI18n } from '@lcifr/pages';
import * as portable from '@lcifr/portable';
import { RelatedProducts } from './RelatedProducts';

export const PortableRelatedProductsType = portable.createType(
  'relatedProducts',
  ({ node, context }) => {
    const i18n = useRawI18n();
    return (
      <RelatedProducts
        width={node?.section?.width || context.defaultWrapWidth}
        minHeight={node?.section?.minHeight}
        bg={node?.section?.bg}
        bgImage={node?.section?.bgImage}
        spaceT={node?.section?.spaceT || 8}
        spaceB={node?.section?.spaceB || 8}
        stackSpace={node?.section?.stackSpace || 1}
        id={node?.section?.htmlID?.current}
        vRhythm={node?.section?.disableVRhythm ? false : true}
        productList={i18n?.relatedProducts?.productList}
        headline={node?.headline || i18n?.relatedProducts?.headline}
        link={i18n?.relatedProducts?.link}
        linkText={i18n?.relatedProducts?.linkText}
        hideButton={node?.hideButton}
      />
    );
  },
);
