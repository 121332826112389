import { createType } from '@lcifr/portable';
import { HeroLanding } from './HeroLanding';

export const PortableHeroLandingType = createType('heroLanding', ({ node }) => {
  return (
    <>
      <HeroLanding
        width={node?.section?.width}
        minHeight={node?.section?.minHeight}
        bg={node?.section?.bg}
        bgImage={node?.section?.bgImage}
        spaceT={node?.section?.spaceT || 0}
        spaceB={node?.section?.spaceB || 0}
        stackSpace={0}
        id={node?.section?.htmlID?.current}
        vRhythm={node?.section?.disableVRhythm ? false : true}
        backText={node?.backText}
        backLink={node?.backLink}
        headline={node?.headline}
        subline={node?.subline}
        image={node?.image}
      />
      {/* <pre>{JSON.stringify(node, null, 2)}</pre> */}
    </>
  );
});
