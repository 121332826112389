export const FERMENTER_ACID_CONCENTRATIONS = [
  0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.11, 0.12, 0.13, 0.14, 0.15, 0.16, 0.17,
  0.18, 0.19, 0.2, 0.21, 0.22, 0.23, 0.24, 0.25,
] as const;

export type FermenterAcidConcentration =
  (typeof FERMENTER_ACID_CONCENTRATIONS)[number];

export const FERMENTER_DEFAULT_ACID_CONCENTRATION =
  0.1 as const satisfies FermenterAcidConcentration;

export const FERMENTER_VINEGAR_TYPES = [
  'alcohol14',
  'alcohol20',
  'wine',
  'apple',
  'rice',
  'other',
] as const;

export type FermenterVinegarType = (typeof FERMENTER_VINEGAR_TYPES)[number];

export const FERMENTER_DEFAULT_VINEGAR_TYPE =
  'alcohol14' as const satisfies FermenterVinegarType;

/**
 * this is the key for the CMS translation of the vinegar type
 */
export const FERMENTER_VINEGAR_TYPE_LABEL_KEY = {
  alcohol14: 'vinegarAlcohol14',
  alcohol20: 'vinegarAlcohol20',
  wine: 'vinegarWine',
  apple: 'vinegarApple',
  rice: 'vinegarRice',
  other: 'vinegarOther',
} as const satisfies Record<FermenterVinegarType, string>;

/**
 * this is the label for the vinegar type used in the email template
 * (this is basically a default translation for the vinegar type)
 */
export const FERMENTER_VINEGAR_TYPE_INTERNAL_LABEL = {
  alcohol14: 'Alcohol vinegar with < 15% acidity',
  alcohol20: 'Alcohol vinegar with 15% - 24% acidity',
  wine: 'Wine vinegar',
  apple: 'Apple vinegar',
  rice: 'Rice vinegar',
  other: 'Other',
} as const;

export enum FERMENTER_TYPE {
  special,
  industrial,
}

export const FERMENTER_TYPE_INTERNAL_LABEL = {
  [FERMENTER_TYPE.special]: 'Special fermenter',
  [FERMENTER_TYPE.industrial]: 'Industrial fermenter',
} as const satisfies Record<FERMENTER_TYPE, string>;

type Fermenter = {
  name: string;
  value: number;
  type: FERMENTER_TYPE;
};

export const FERMENTERS = [
  { name: 'SF6', value: 900, type: FERMENTER_TYPE.special },
  { name: 'SF50', value: 8000, type: FERMENTER_TYPE.special },
  { name: 'SF100', value: 16000, type: FERMENTER_TYPE.special },
  { name: 'SF300', value: 47000, type: FERMENTER_TYPE.special },
  { name: 'SF600', value: 94000, type: FERMENTER_TYPE.special },
  { name: 'SF1000', value: 157000, type: FERMENTER_TYPE.special },
  { name: 'SF1500', value: 236000, type: FERMENTER_TYPE.special },
  { name: 'F30', value: 470000, type: FERMENTER_TYPE.industrial },
  { name: 'F60', value: 940000, type: FERMENTER_TYPE.industrial },
  { name: 'F90', value: 1420000, type: FERMENTER_TYPE.industrial },
  { name: 'F120', value: 1900000, type: FERMENTER_TYPE.industrial },
  { name: 'F200', value: 3100000, type: FERMENTER_TYPE.industrial },
  { name: 'F250', value: 4200000, type: FERMENTER_TYPE.industrial },
  { name: 'F300', value: 5000000, type: FERMENTER_TYPE.industrial },
  { name: 'F400', value: 6600000, type: FERMENTER_TYPE.industrial },
  { name: 'F500', value: 8200000, type: FERMENTER_TYPE.industrial },
  { name: 'F600', value: 9900000, type: FERMENTER_TYPE.industrial },
  { name: 'F750', value: 12300000, type: FERMENTER_TYPE.industrial },
  { name: 'F850', value: 15400000, type: FERMENTER_TYPE.industrial },
  { name: 'F1100', value: 20000000, type: FERMENTER_TYPE.industrial },
  { name: 'F1400', value: 25000000, type: FERMENTER_TYPE.industrial },
] as const satisfies Fermenter[];

export enum FERMENTER_NUTRITION_TYPE {
  complete,
  wine,
}

export const FERMENTER_NUTRITION_TYPE_LABEL = {
  [FERMENTER_NUTRITION_TYPE.complete]: 'Nutracet complete',
  [FERMENTER_NUTRITION_TYPE.wine]: 'Nutracet wine',
} as const satisfies Record<FERMENTER_NUTRITION_TYPE, string>;
