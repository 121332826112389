import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { sx } from '@lcifr/sx';
import { useTheme, mapResponsiveValue } from '@lcifr/theming';
import { Wrap as LuciferWrap } from '@lcifr/components/wrap';
import { Heading } from '@lcifr/components/heading';
import { Button } from '@lcifr/components/button';
import { Stack } from '@lcifr/components/stack';
import { Icon } from '@lcifr/components/icon';
import { Video as MuxVideo } from 'components/Video';
import { Inline } from '@lcifr/components/inline';

export const Video = (sx as any)(MuxVideo)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  objectFit: 'cover',
  '& > div': { height: '100%' },
  '& video': {
    height: '100%',
    width: (t) =>
      mapResponsiveValue(t.wraps, (width, i) =>
        i > 1 ? `calc(${width} + (100vw - ${width}) / 2)` : undefined,
      ),
  },
});

function openFullscreen(elem) {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  }
}

type HeroVideoProps = {
  videoId: any;
  videoPreviewId: any;
  buttonText: any;
  headline: any;
};

export function HeroVideo({
  videoId,
  videoPreviewId,
  buttonText,
  headline,
}: HeroVideoProps) {
  const [isActive, setIsActive] = React.useState(false);
  const videoRef = React.useRef();
  // const videoHlsConfig = (videoRef as any)?.current?.hls?.config;
  // videoHlsConfig.startLevel = 4;
  const videoElement = (videoRef as any)?.current?.video?.current;

  React.useEffect(() => {
    if (isActive && videoElement) {
      openFullscreen(videoElement);
    }
  }, [isActive, videoElement]);

  const currentVideoId = isActive ? videoId : videoPreviewId;

  const handleFullscreen = () => {
    if (videoElement === document.fullscreenElement) {
      // enter fullscreen
      videoElement.currentTime = 0;
      videoElement.muted = false;
      videoElement.play();
    } else {
      // exit fullscreen
      videoElement.muted = true;
      setIsActive(false);
      videoElement.play();
    }
  };

  React.useEffect(() => {
    const videoElement = (videoRef as any)?.current?.video?.current;
    videoElement?.addEventListener('fullscreenchange', handleFullscreen);

    return () => {
      videoElement?.removeEventListener('fullscreenchange', handleFullscreen);
    };
  });

  const theme = useTheme();

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <LuciferWrap>
        <Box
          sx={{ position: 'relative', paddingTop: ['150%', '130%', '56.65%'] }}
        >
          <Video
            ref={videoRef}
            videoAssetId={currentVideoId}
            showControls={isActive}
            loop
            muted
            autoplay
          />
          {(headline || buttonText) && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                right: ['-8px', '-8px', 0],
                pt: [4, 4, 6],
                pb: [2, 2, 6],
                pl: [5, 5, 8],
                pr: [5, 5, undefined],
                bg: 'background',
                width: ['90%', '90%', '45%'],
                textAlign: 'left',
                transform: isActive ? 'translateY(100%)' : undefined,
                transition: 'transform 1s ease-out',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  left: '100%',
                  top: 0,
                  py: 'inherit',
                  width: mapResponsiveValue(theme.wraps, (width, i) =>
                    i > 1 ? `calc(((100vw - ${width}) / 2))` : 0,
                  ),
                  bg: 'background',
                  height: '100%',
                },
              }}
            >
              <Stack space={3}>
                {headline && <Heading variant="h3">{headline}</Heading>}
                {buttonText && (
                  <Inline>
                    <Button variant="small" onClick={() => setIsActive(true)}>
                      <Icon name="play" sx={{ mr: 2 }} /> {buttonText}
                    </Button>
                  </Inline>
                )}
              </Stack>
            </Box>
          )}
        </Box>
      </LuciferWrap>
    </Box>
  );
}
