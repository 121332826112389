import * as portable from '@lcifr/portable';
import { ImpressionSingle } from './ImpressionSingle';

export const PortableImpressionSingleType = portable.createType(
  'impressionSingle',
  ({ node }) => {
    return (
      <ImpressionSingle
        width={node?.section?.width}
        minHeight={node?.section?.minHeight}
        bg={node?.section?.bg}
        bgImage={node?.section?.bgImage}
        spaceT={node?.section?.spaceT || 0}
        spaceB={node?.section?.spaceB || 8}
        stackSpace={node?.section?.stackSpace || 5}
        id={node?.section?.htmlID?.current}
        vRhythm={node?.section?.disableVRhythm ? false : true}
        image={node?.image}
      />
    );
  },
);
