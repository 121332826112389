import { createType } from '@lcifr/portable';
import { HeroVideo } from './HeroVideo';

export const PortableHeroVideoType = createType('heroVideo', ({ node }) => {
  return (
    <>
      <HeroVideo
        videoId={node?.video?.video?.asset?._ref}
        videoPreviewId={node?.videoPreview?.video?.asset?._ref}
        headline={node?.headline}
        buttonText={node?.buttonText}
      />
      {/* <pre>{JSON.stringify(node, null, 2)}</pre> */}
    </>
  );
});
