import * as React from 'react';
import { AllVideosReadyProvider } from './VideoList.context';
import VideoListWithoutProvider from './VideoListWithoutProvider';

export function VideoList({ children }) {
  return (
    <AllVideosReadyProvider>
      <VideoListWithoutProvider>{children}</VideoListWithoutProvider>
    </AllVideosReadyProvider>
  );
}
