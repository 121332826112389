import * as React from 'react';
import { sx } from '@lcifr/sx';
import { Heading } from '@lcifr/components/heading';
import { Box } from '@lcifr/components/box';
import { CollectionType } from '@lcifr/components/collection';
import { Image } from 'components/Image';
import { usePortableNodes } from '@lcifr/components/portable-nodes';
import { Stack } from '@lcifr/components/stack';

export const Headline = sx(Heading)({});

type ServiceSectionProps = {
  serviceList: any;
  stackSpace: any;
  image: any;
  headline: any;
};

export function ServiceSection({
  serviceList,
  stackSpace,
  image,
  headline,
}: ServiceSectionProps) {
  const PortableNodes = usePortableNodes();
  return (
    <Box
      sx={{
        display: 'grid',
        gap: [40, 40, 80],
        gridTemplateColumns: ['1fr', '1fr', '1fr 2fr'],
      }}
    >
      <Heading as="h1" variant="h1">
        {headline}
      </Heading>
      <Box
        sx={{
          display: 'grid',
          gap: [40, 40, 40],
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
        }}
      >
        <Image
          image={image}
          alt={headline}
          sx={{
            position: 'relative',
            paddingTop: '100%',
            borderRadius: '100%',
          }}
        />
        <CollectionType node={serviceList}>
          {(services) => (
            <>
              {services.map((service) => (
                <Box
                  key={(service as any)?._id}
                  sx={{ bg: 'light', px: 4, py: 6 }}
                >
                  <Stack space={stackSpace}>
                    <Heading as="h4" variant="h4">
                      {(service as any)?.name}
                    </Heading>
                    {(service as any)?.content?.length && (
                      <PortableNodes blocks={(service as any)?.content}>
                        {(children) => (
                          <Stack space={stackSpace}>{children}</Stack>
                        )}
                      </PortableNodes>
                    )}
                  </Stack>
                </Box>
              ))}
            </>
          )}
        </CollectionType>
      </Box>
    </Box>
  );
}
