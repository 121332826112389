import { createType } from '@lcifr/portable';
import { HeroProducts } from './HeroProducts';

export const PortableHeroProductsType = createType(
  'heroProducts',
  ({ node }) => {
    return (
      <>
        <HeroProducts itemList={node?.heroItemList?.[0]?.item} />
        {/* <pre>{JSON.stringify(node, null, 2)}</pre> */}
      </>
    );
  },
);
