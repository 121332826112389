import * as React from 'react';
import { sx } from '@lcifr/sx';
import { Icon as IconLucifer } from '@lcifr/components/icon';

export const List = sx('ul')({
  listStyle: 'none',
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '20px',
  mt: 2,
  mb: 2,
});

export const ListItem = sx('li')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 2,
});

export const Icon = sx(IconLucifer)({
  marginRight: 2,
});

export function ListArrow({ items }) {
  return (
    <List>
      {items.map((item) => (
        <ListItem key={item.key}>
          <Icon name="chev-right" /> {item.props.children[0]}
        </ListItem>
      ))}
    </List>
  );
}
