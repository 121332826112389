import * as portable from '@lcifr/portable';
import { SalespartnerSection } from './SalespartnerSection';

export const PortableSalespartnerSectionType = portable.createType(
  'salespartnerSection',
  ({ node, context }) => (
    <SalespartnerSection
      width={node?.section?.width || context?.defaultWrapWidth}
      minHeight={node?.section?.minHeight}
      bg={node?.section?.bg}
      bgImage={node?.section?.bgImage}
      spaceT={node?.section?.spaceT || 8}
      spaceB={node?.section?.spaceB || 8}
      stackSpace={node?.stackSpace || 5}
      id={node?.section?.htmlID?.current}
      vRhythm={node?.section?.disableVRhythm ? false : true}
    />
  ),
);
