import * as React from 'react';
import type { Flags } from 'flagpack-core';
import { sx } from '@lcifr/sx';
import { Box } from '@lcifr/components/box';

export const Img = sx('img')({
  width: 36,
  height: 36,
  borderRadius: '50%',
  objectFit: 'cover',
});

interface Props {
  code: any; // Flags;
  className?: string;
  sx?: any;
}

export const Flag: React.FC<Props> = ({
  code = 'NL',
  className,
  sx,
}: Props) => (
  <Box sx={sx} className={className}>
    <Img src={`/flags/${code}.svg`} alt={code} />
  </Box>
);
