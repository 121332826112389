import * as React from 'react';
import { Section } from '@lcifr/components/section';
import { Box } from '@lcifr/components/box';
import { Stack } from '@lcifr/components/stack';
import { CenterY } from '@lcifr/components/center';
import { useRawI18n } from '@lcifr/pages';
import { usePortableNodes } from '@lcifr/components/portable-nodes';
import { Image } from 'components/Image';

type TextMediaSectionProps = {
  stackSpace?: any;
  width?: any;
  minHeight?: any;
  bg?: any;
  bgImage?: any;
  spaceT?: any;
  spaceB?: any;
  id?: any;
  vRhythm?: any;
  content?: any;
  image: any;
  isBoxed: any;
  isReversed: any;
};

export function TextMediaSection({
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
  content,
  image,
  isBoxed,
  isReversed,
}: TextMediaSectionProps) {
  const PortableNodes = usePortableNodes();
  return (
    <Section
      width={width}
      minHeight={minHeight}
      bg={bg}
      bgImage={bgImage}
      spaceT={spaceT || null}
      spaceB={spaceB || 8}
      stackSpace={stackSpace}
      id={id}
      vRhythm={vRhythm}
    >
      <Box sx={{ bg: isBoxed && 'light' }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: [
              '1fr',
              '1fr',
              isReversed ? '40% 60%' : '60% 40%',
            ],
            gap: [20, 20, 80],
            width: ['100%', '100%', 'calc(100% - 80px)'],
            boxSizing: 'border-box',
          }}
        >
          <Box
            sx={{
              boxSizing: 'border-box',
              p: isBoxed
                ? isReversed
                  ? ['20px', '20px', '80px 80px 80px 0']
                  : ['20px', '20px', '80px 0 80px 80px']
                : [null, null, '80px 0'],
            }}
          >
            <CenterY>
              <PortableNodes blocks={content}>
                {(children) => <Stack space={stackSpace}>{children}</Stack>}
              </PortableNodes>
            </CenterY>
          </Box>
          <Box sx={{ order: [-1, -1, isReversed ? -1 : 1] }}>
            <Image
              sx={{
                position: 'relative',
                height: ['auto', 'auto', '100%'],
                pt: ['60%', '60%', null],
              }}
              image={image}
              alt=""
            />
          </Box>
        </Box>
      </Box>
    </Section>
  );
}
