import * as React from 'react';
import { sx } from '@lcifr/sx';
import { Heading } from '@lcifr/components/heading';
import { Box } from '@lcifr/components/box';
import { alpha } from '@lcifr/theming';
import { Icon } from '@lcifr/components/icon';
import { Stack } from '@lcifr/components/stack';
import { Section } from '@lcifr/components/section';
import {
  RawPageShard,
  useCollection,
  useRawI18n,
  useTranslation,
} from '@lcifr/pages';
import { Flag } from 'components/Flag';
import { usePortableNodes } from '@lcifr/components/portable-nodes';
import { useGetCountryName } from 'utils/hooks/useGetCountryName';
import { useRouter } from 'next/router';

export const Headline = sx(Heading)({});

const handleScrollClick = (id: string) => {
  /**
   * using scrollIntoView instead of css:`scroll-behaviour: smooth` because of
   * https://github.com/vercel/next.js/issues/12105#issuecomment-908317568
   */

  if (typeof document !== 'undefined') {
    const element = document.getElementById(id.replace('#', ''));
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
};

type SalespartnerSectionProps = {
  stackSpace?: any;
  width?: any;
  minHeight?: any;
  bg?: any;
  bgImage?: any;
  spaceT?: any;
  spaceB?: any;
  id?: any;
  vRhythm?: any;
  content?: any;
};

export function SalespartnerSection({
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
}: SalespartnerSectionProps) {
  const { getCountryName } = useGetCountryName();
  const PortableNodes = usePortableNodes();
  const i18n = useRawI18n();
  const salespartnerList = useCollection(
    i18n?.salespartnerSection?.salespartnerList,
  );
  const t = useTranslation();

  const regions: { region: string; items: RawPageShard[] }[] = [];

  salespartnerList.forEach((item, i) => {
    const region = regions.find(
      (region) => (region as any).region === (item as any).region,
    );
    if (region) {
      (region as any).items.push({ ...(item as any), tabId: i });
    } else {
      regions.push({
        region: (item as any).region,
        items: [{ ...(item as any), tabId: i }],
      });
    }
  });

  const router = useRouter();
  const currentId = router.asPath.split('#')[1];

  function setCurrentId(id?: string) {
    if (id) {
      router.push(`${router.asPath.split('#')[0]}#${id}`, undefined, {
        scroll: false,
      });
    }
  }

  React.useEffect(() => {
    handleScrollClick(`#${currentId}`);
  }, [currentId]);

  return (
    <Section
      width={width}
      minHeight={minHeight}
      bg={bg}
      bgImage={bgImage}
      spaceT={spaceT || null}
      spaceB={spaceB || 8}
      stackSpace={stackSpace}
      id={id}
      vRhythm={vRhythm}
    >
      <Stack space={[4, 4, 8]}>
        {regions.map((region, i) => (
          <Stack space={2} key={`region-${i}`}>
            <Heading variant="h2" sx={{ pb: 4 }}>
              {t((region as any)?.region)}
            </Heading>
            <Stack space={0}>
              {(region as any).items.map((item, i) => (
                <Box
                  key={`partner-${i}`}
                  sx={{
                    position: 'relative',
                    borderTop: '1px solid',
                    borderColor: alpha('secondary', 0.1),
                    pb: [2, 2, 3],
                    pt: [2, 2, 3],
                    '&:first-of-type': {
                      pt: 0,
                      borderTop: 0,
                    },
                    '&:last-of-type': {
                      pb: 0,
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      left: 0,
                      top: [-72, -72, -92],
                      width: '100%',
                      height: 1,
                    }}
                    id={(item as any)?.id}
                  />
                  <Box
                    id={(item as any)?.id}
                    sx={{
                      display: 'flex',
                      gap: 20,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      cursor: currentId === item.id ? 'auto' : 'pointer',
                      userSelect: currentId === item.id ? 'auto' : 'none',
                    }}
                    onClick={() => {
                      currentId === item.id
                        ? setCurrentId()
                        : setCurrentId((item as any)?.id);
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: ['8px', '8px', '8px'],
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                      }}
                    >
                      <Heading as="h4" variant="h4">
                        {(item as any)?.partner}
                      </Heading>
                      <Heading
                        variant="h6"
                        sx={{
                          wordBreak: 'break-word',
                          color: alpha('secondary', 0.3),
                        }}
                      >
                        {(item as any)?.jurisdication?.map(
                          (jurisdication, i) => (
                            <Box
                              key={`jurisdiscation-${i}`}
                              as="span"
                              sx={{
                                '&:after': {
                                  content: '", "',
                                },
                                '&:last-of-type': {
                                  '&:after': {
                                    content: '""',
                                  },
                                },
                              }}
                            >
                              {getCountryName(jurisdication?.country)}
                            </Box>
                          ),
                        )}
                      </Heading>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: ['12px'],
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: ['wrap', 'wrap', 'nowrap'],
                          gap: '8px',
                        }}
                      >
                        {(item as any)?.jurisdication?.map(
                          (jurisdication, i) => (
                            <Flag
                              key={`country-${i}`}
                              code={jurisdication?.country}
                              sx={{
                                img: {
                                  boxShadow: '2px 2px 4px rgba(0,0,0,0.1)',
                                },
                              }}
                            />
                          ),
                        )}
                      </Box>
                      <Icon
                        name="chev-right"
                        sx={{
                          color: alpha('secondary', 0.3),
                          transform:
                            currentId === item.id ? 'rotate(90deg)' : null,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      pt: currentId === item.id ? '12px' : 0,
                      height: currentId === item.id ? 'auto' : 0,
                      opacity: currentId === item.id ? 1 : 0,
                      overflow: 'hidden',
                    }}
                  >
                    <PortableNodes blocks={item.content} />
                  </Box>
                </Box>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Section>
  );
}
