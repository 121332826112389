import * as React from 'react';
import { Section } from '@lcifr/components/section';
import { LinkStudio } from '@lcifr/components/link';
import { Box } from '@lcifr/components/box';
import { Heading } from '@lcifr/components/heading';
import { Button } from '@lcifr/components/button';
import { useRawI18n } from '@lcifr/pages';
import { CollectionType } from '@lcifr/components/collection';
import { Image as WrappedImage } from 'components/Image';
import { Stack } from '@lcifr/components/stack';
import { sx } from '@lcifr/sx';
import { Anchor } from '@lcifr/components/anchor';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { usePortableNodes } from '@lcifr/components/portable-nodes';
import { Text } from '@lcifr/components/text';

export const Image = sx(WrappedImage)({
  paddingTop: `${(3 / 5) * 100}%`,
  position: 'relative',
  overflow: 'hidden',
});

export const Tile = sx(Box)({
  bg: 'light',
  // img: {
  //   transition: 'all ease-out 0.4s !important',
  // },
  // '&:hover': {
  //   img: {
  //     transform: 'scale(0.8)',
  //   },
  // },
});

type ProductFocusListProps = {
  stackSpace: any;
  width: any;
  minHeight: any;
  bg: any;
  bgImage: any;
  spaceT: any;
  spaceB: any;
  id: any;
  vRhythm: any;
  productList: any;
  content: any;
};

export function ProductFocusList({
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
  productList,
  content,
}: ProductFocusListProps) {
  const i18n = useRawI18n();
  const linkText = i18n?.productFocusSection?.readmoreText;
  const PortableNodes = usePortableNodes();

  return (
    <Section
      width={width}
      minHeight={minHeight}
      bg={bg}
      bgImage={bgImage}
      spaceT={spaceT}
      spaceB={spaceB}
      stackSpace={stackSpace || 0}
      id={id}
      vRhythm={vRhythm}
    >
      {content ? (
        <PortableNodes blocks={content}>
          {(children) => <Stack space={stackSpace}>{children}</Stack>}
        </PortableNodes>
      ) : null}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
          gap: 40,
          pt: ['8px', '8px', 40],
          userSelect: 'none',
          width: '100%',
        }}
      >
        <CollectionType node={productList}>
          {(products) => {
            return (products as any).map((product) => (
              <Tile key={(product as any)._id}>
                <Stack space={[1, 1, 3]}>
                  <Image
                    sx={{
                      background:
                        'linear-gradient(180deg, rgba(15, 17, 37, 0.2) 0%, rgba(15, 17, 37, 0) 100%)',
                      img: {
                        transform: 'scale(0.7)',
                        objectFit: 'contain !important',
                      },
                    }}
                    image={(product as any).imageCutout}
                    alt=""
                  />
                  <Stack space={4} sx={{ px: [4, 4, 7], pb: [6, 6, 7] }}>
                    <Heading as="h4">{(product as any).promoHeadline}</Heading>
                    <Text>{(product as any).promoText}</Text>
                    {(product as any)?.slug ? (
                      <Link
                        href={(product as any)?.slug}
                        locale={(product as any)?.locale}
                        passHref
                        legacyBehavior
                      >
                        <Anchor variant="download">{linkText}</Anchor>
                      </Link>
                    ) : null}
                  </Stack>
                </Stack>
              </Tile>
            ));
          }}
        </CollectionType>
      </Box>
    </Section>
  );
}
