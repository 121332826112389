import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { Wrap as LuciferWrap } from '@lcifr/components/wrap';
import { Image } from 'components/Image';
import { sx } from '@lcifr/sx';
import { Anchor } from '@lcifr/components/anchor';
import { CollectionType } from '@lcifr/components/collection';
import Link from 'next/link';
import { Heading } from '@lcifr/components/heading';
import { useCollection, useRawI18n } from '@lcifr/pages';
import { Button } from '@lcifr/components/button';
import { Icon } from '@lcifr/components/icon';
import { Stack } from '@lcifr/components/stack';
import { LinkStudio } from '@lcifr/components/link';
import { Section } from '@lcifr/components/section';
import { mapResponsiveValue, useTheme } from '@lcifr/theming';

export const Stage = sx(Box)({
  background:
    'linear-gradient(180deg, rgba(15, 17, 37, 0.1) 0%, rgba(15, 17, 37, 0.03) 100%)',
});

type HeroLandingProps = {
  width?: any;
  minHeight?: any;
  bg?: any;
  bgImage?: any;
  spaceT?: any;
  spaceB?: any;
  stackSpace?: any;
  id?: any;
  vRhythm?: any;
  backText?: any;
  backLink?: any;
  headline: any;
  subline?: any;
  image: any;
};

export function HeroLanding({
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
  backText,
  backLink,
  headline,
  subline,
  image,
}: HeroLandingProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        overflow: 'hidden',
        transition: '0.5s margin ease-out',
        pb: [4, 4, 8],
      }}
    >
      <LuciferWrap>
        <Box sx={{ position: 'relative', paddingTop: ['150%', '150%', '65%'] }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          >
            <Box
              sx={{
                position: ['static', 'static', 'absolute'],
                top: 0,
                right: 0,
                height: '100%',
                width: (t) =>
                  mapResponsiveValue(t.wraps, (width, i) =>
                    i > 1
                      ? `calc(${width} + (100vw - ${width}) / 2)`
                      : undefined,
                  ),
                background:
                  'linear-gradient(180deg, rgba(15, 17, 37, 0.1) 0%, rgba(15, 17, 37, 0.03) 100%)',
              }}
            >
              <Image
                image={image}
                sx={{
                  height: '100%',
                  'span, img': {
                    position: ['static', 'static', 'absolute'],
                    top: 0,
                    right: 0,
                    width: (t) =>
                      mapResponsiveValue(t.wraps, (width, i) =>
                        i > 1
                          ? `calc(${width} + (100vw - ${width}) / 2) !important`
                          : undefined,
                      ),
                  },
                }}
                alt=""
              />
            </Box>
          </Box>

          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: ['-8px', '-8px', 0],
              pt: [4, 4, 6],
              pb: [2, 2, 6],
              pr: [5, 5, 8],
              pl: ['8px', '8px', 0],
              bg: 'background',
              width: ['95%', '95%', 'calc(66.6666% - 44px)'],
              textAlign: 'left',
              transition:
                '0.5s padding-top ease-out, 0.5s padding-bottom ease-out',
              '&:after': {
                content: '""',
                position: 'absolute',
                right: '100%',
                top: 0,
                py: 'inherit',
                width: mapResponsiveValue(theme.wraps, (width, i) =>
                  i > 1 ? `calc(((100vw - ${width}) / 2))` : 0,
                ),
                bg: 'background',
                height: '100%',
              },
            }}
          >
            <Stack space={3}>
              <LinkStudio link={backLink}>
                <Button
                  variant="strippedPrimary"
                  sx={{
                    fontSize: '16px !important',
                    textTransform: 'uppercase',
                    letterSpacing: '3px',
                  }}
                >
                  <Icon name="chev-left" sx={{ mr: 2, color: 'primary' }} />
                  {backText}
                </Button>
              </LinkStudio>
              {headline && <Heading variant="h1">{headline}</Heading>}
              {subline && (
                <Heading variant="h4" sx={{ fontWeight: 400 }}>
                  {subline}
                </Heading>
              )}
            </Stack>
          </Box>
        </Box>
      </LuciferWrap>
    </Box>
  );
}
