import * as portable from '@lcifr/portable';
import { CertificateSection } from './CertificateSection';
import { Section } from '@lcifr/components/section';

export const PortableCertificateSectionType = portable.createType(
  'certificateSection',
  ({ node, context }) => (
    <Section
      width={node?.section?.width || context?.defaultWrapWidth}
      minHeight={node?.section?.minHeight}
      bg={node?.section?.bg}
      bgImage={node?.section?.bgImage}
      spaceT={node?.section?.spaceT || 8}
      spaceB={node?.section?.spaceB || 8}
      stackSpace={0}
      id={node?.section?.htmlID?.current}
      vRhythm={node?.section?.disableVRhythm ? false : true}
    >
      <CertificateSection
        certificateList={node?.certificateList}
        stackSpace={node?.stackSpace || 3}
        headline={node?.headline}
      />
    </Section>
  ),
);
