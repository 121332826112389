import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { Image as LuciferImage } from '@lcifr/components/image';

type ImageProps = {
  sx?: any;
  alt?: string;
  aspectRatio?: number;
  blur?: number;
  className?: string;
  fadeDuration?: any;
  fadeIn?: boolean;
  fadeTimingFunction?: any;
  flipHorizontal?: boolean;
  flipVertical?: boolean;
  grayscale?: boolean;
  height?: number;
  image: any;
  invert?: boolean;
  loading?: 'lazy' | 'eager';
  maxWidth?: number;
  orientation?: 0 | 90 | 180 | 270;
  placeholder?: boolean;
  quality?: number;
  sizes?: string;
  title?: string;
  width?: number;
  objectFit?: 'cover' | 'contain';
};

export const Image = React.forwardRef(function Image(
  {
    sx,
    aspectRatio,
    className,
    fadeDuration,
    fadeIn,
    fadeTimingFunction,
    height,
    image,
    loading,
    maxWidth,
    placeholder,
    quality,
    sizes,
    width,
    ...rest
  }: ImageProps,
  ref,
) {
  return image ? (
    <Box className={className} sx={sx}>
      <LuciferImage image={image} {...rest} />
    </Box>
  ) : null;
});
