import * as React from 'react';
import { Section } from '@lcifr/components/section';
import { LinkStudio } from '@lcifr/components/link';
import { Box } from '@lcifr/components/box';
import { Heading } from '@lcifr/components/heading';
import { Button } from '@lcifr/components/button';
import { useRawI18n } from '@lcifr/pages';
import { CollectionType } from '@lcifr/components/collection';
import { Image as WrappedImage } from 'components/Image';
import { Stack } from '@lcifr/components/stack';
import { sx } from '@lcifr/sx';
import { Anchor } from '@lcifr/components/anchor';
import Link from 'next/link';
import { useRouter } from 'next/router';

export const Image = sx(WrappedImage)({
  paddingTop: `${(3 / 5) * 100}%`,
  position: 'relative',
  overflow: 'hidden',
});

export const Tile = sx(Anchor)({
  img: {
    transition: 'all ease-out 0.4s !important',
  },
  '&:hover': {
    img: {
      transform: 'scale(1.05)',
    },
  },
});

type ProductTechnologyListProps = {
  stackSpace: any;
  width: any;
  minHeight: any;
  bg: any;
  bgImage: any;
  spaceT: any;
  spaceB: any;
  id: any;
  vRhythm: any;
  productTechnologies: any;
};

export function ProductTechnologyList({
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
  productTechnologies,
}: ProductTechnologyListProps) {
  const i18n = useRawI18n();
  const headline = i18n?.productTechnology?.headline;
  const link = i18n?.productTechnology?.link;
  const linkText = i18n?.productTechnology?.linkText;

  const { locale } = useRouter();

  return (
    <Section
      width={width}
      minHeight={minHeight}
      bg={bg}
      bgImage={bgImage}
      spaceT={spaceT}
      spaceB={spaceB}
      stackSpace={stackSpace}
      id={id}
      vRhythm={vRhythm}
    >
      {headline || (linkText && link) ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: ['flex-start', 'flex-start', 'flex-end'],
            justifyContent: 'space-between',
            flexDirection: ['column', 'column', 'row'],
            gap: 4,
          }}
        >
          {headline && <Heading as="h1">{headline}</Heading>}
          {linkText && link && (
            <LinkStudio link={link}>
              <Button variant="small">{linkText}</Button>
            </LinkStudio>
          )}
        </Box>
      ) : null}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr 1fr'],
          gap: 40,
          pt: ['24px', '24px', 40],
          userSelect: 'none',
        }}
      >
        <CollectionType node={productTechnologies}>
          {(technologies) =>
            (technologies as any).map((technology) => (
              <Link
                href={`/${(technology as any)?.slugs?.join('/')}`}
                passHref
                legacyBehavior
                locale={locale}
                key={(technology as any)._id}
              >
                <Tile>
                  <Stack space={3}>
                    <Image image={(technology as any).image} alt="" />
                    <Heading variant="h4" as="h2">
                      {(technology as any).name}
                    </Heading>
                  </Stack>
                </Tile>
              </Link>
            ))
          }
        </CollectionType>
      </Box>
    </Section>
  );
}
