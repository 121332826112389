import * as portable from '@lcifr/portable';
import { QuoteExtended } from 'components/QuoteExtended';

export const PortableQuoteExtendedType = portable.createType(
  'quoteExtended',
  ({ node }) => {
    return (
      <QuoteExtended
        quote={node?.quote}
        image={node?.image}
        source={node?.source}
      />
    );
  },
);
