import { createType } from '@lcifr/portable';
import { InfoSection } from './InfoSection';

export const PortableInfoSectionType = createType('infoSection', ({ node }) => {
  return (
    <>
      <InfoSection
        infoList={node?.infoList}
        width={node?.section?.width}
        minHeight={node?.section?.minHeight}
        bg={node?.section?.bg}
        bgImage={node?.section?.bgImage}
        spaceT={node?.section?.spaceT}
        spaceB={node?.section?.spaceB}
        stackSpace={node?.section?.stackSpace || 6}
        id={node?.section?.htmlID?.current}
        vRhythm={node?.section?.disableVRhythm ? false : true}
        isBoxed={node?.isBoxed}
        content={node?.section?.blocks}
      />
      {/* <pre>{JSON.stringify(node, null, 2)}</pre> */}
    </>
  );
});
