import { createType } from '@lcifr/portable';
import { NewsSection } from './NewsSection';

export const PortableNewsSectionType = createType('newsSection', ({ node }) => {
  return (
    <>
      <NewsSection
        articleList={node?.articleList}
        width={node?.section?.width}
        minHeight={node?.section?.minHeight}
        bg={node?.section?.bg}
        bgImage={node?.section?.bgImage}
        spaceT={node?.section?.spaceT || 8}
        spaceB={node?.section?.spaceB || 8}
        stackSpace={0}
        id={node?.section?.htmlID?.current}
        vRhythm={node?.section?.disableVRhythm ? false : true}
        cutFirst={node?.cutFirst || false}
      />
      {/* <pre>{JSON.stringify(node, null, 2)}</pre> */}
    </>
  );
});
