import * as portable from '@lcifr/portable';
import { ImpressionMultiple } from './ImpressionMultiple';

export const PortableImpressionMultipleType = portable.createType(
  'impressionMultiple',
  ({ node }) => {
    return (
      <ImpressionMultiple
        width={node?.section?.width}
        minHeight={node?.section?.minHeight}
        bg={node?.section?.bg}
        bgImage={node?.section?.bgImage}
        spaceT={node?.section?.spaceT || 8}
        spaceB={node?.section?.spaceB || 8}
        stackSpace={node?.section?.stackSpace || 5}
        id={node?.section?.htmlID?.current}
        vRhythm={node?.section?.disableVRhythm ? false : true}
        impressionList={node?.impressionList}
      />
    );
  },
);
