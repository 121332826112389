import * as React from 'react';
import { sx } from '@lcifr/sx';
import { Section } from '@lcifr/components/section';
import { Image } from 'components/Image';
import { useScrolledIntoView } from 'utils/hooks/useScrolledIntoView';
import { Box } from '@lcifr/components/box';

export const ImageOne = sx(Image)(({ isActive, setting }) => ({
  position: 'relative',
  height: ['auto', 'auto', '100%'],
  pt: ['56.25%', '56.25%', undefined],
  transform: [
    undefined,
    undefined,
    isActive ? (setting as any).active : (setting as any).inActive,
  ],
  opacity: isActive ? 1 : 0,
  transformOrigin: 'top center',
  transition: 'transform ease-out 1s, opacity ease-out 0.5s',
}));

type ImpressionMultipleProps = {
  impressionList: any;
  stackSpace: any;
  width: any;
  minHeight: any;
  bg: any;
  bgImage: any;
  spaceT: any;
  spaceB: any;
  id: any;
  vRhythm: any;
};

export function ImpressionMultiple({
  impressionList,
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
}: ImpressionMultipleProps) {
  const ref = React.useRef<Element>();

  const [didScrollIntoView] = useScrolledIntoView({
    retrigger: false,
    target: ref,
    threshold: 0.6,
  });

  const setting = [
    { active: 'translateX(0)', inActive: 'translateX(-100%)' },
    { active: 'translateX(0)', inActive: 'translateX(-200%)' },
    { active: 'translateX(0)', inActive: 'translateX(0)' },
    { active: 'translateY(0)', inActive: 'translateY(100%)' },
    { active: 'translateY(0)', inActive: 'translateY(200%)' },
    { active: 'translateY(0)', inActive: 'translateY(300%)' },
    { active: 'translateX(0)', inActive: 'translateX(150%)' },
  ];

  return (
    <Section
      width={width || 'full'}
      minHeight={minHeight}
      bg={bg}
      bgImage={bgImage}
      spaceT={spaceT}
      spaceB={spaceB}
      stackSpace={stackSpace}
      id={id}
      vRhythm={vRhythm}
    >
      <Box
        ref={ref}
        sx={{
          display: 'grid',
          overflow: 'hidden',
          gridTemplateAreas: [
            `"${['i1 i1', 'i2 i3', 'i4 i4', 'i5 i6', 'i7 i7'].join('" "')}"`,
            `"${['i1 i1', 'i2 i3', 'i4 i4', 'i5 i6', 'i7 i7'].join('" "')}"`,
            `"${[
              'a  i3 i3  b',
              'i1 i3 i3 i7',
              'i1 i4 i5 i7',
              'i2 i4 i5 i7',
              'i2 i6 i6 i7',
              'c  i6 i6  d',
            ].join('" "')}"`,
          ],
          minHeight: ['auto', 'auto', '100vh'],
          gap: ['8px', '8px', 40],
        }}
      >
        {impressionList.map((impression, i) => (
          <ImageOne
            key={`impression-${i}`}
            sx={{ gridArea: `i${i + 1}` }}
            image={impression}
            isActive={didScrollIntoView}
            setting={setting[i]}
          />
        ))}
      </Box>
    </Section>
  );
}
