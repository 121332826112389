import * as React from 'react';
import { FormFermenter } from 'components/FormFermenter';
import { Section } from '@lcifr/components/section';

type FormSectionProps = {
  variant: any;
  width?: any;
  minHeight?: any;
  bg?: any;
  bgImage?: any;
  spaceT?: any;
  spaceB?: any;
  stackSpace?: any;
  id?: any;
  vRhythm?: any;
};

export function FormSection({
  variant,
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
}: FormSectionProps) {
  switch (variant) {
    default:
    case 'formFermenter':
      return (
        <Section
          width={width}
          minHeight={minHeight}
          bg={bg}
          bgImage={bgImage}
          spaceT={spaceT}
          spaceB={spaceB}
          stackSpace={stackSpace}
          id={id}
          vRhythm={vRhythm}
        >
          <FormFermenter />
        </Section>
      );
  }
}
