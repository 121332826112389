import * as React from 'react';
import * as portable from '@lcifr/portable';
import { Section } from '@lcifr/components/section';
import { TeaserSection } from './TeaserSection';

export const PortableTeaserSectionType = portable.createType(
  'teaserSection',
  ({ node, context }) => (
    <Section
      width={node?.section?.width || context?.defaultWrapWidth}
      minHeight={node?.section?.minHeight}
      bg={node?.section?.bg}
      bgImage={node?.section?.bgImage}
      spaceT={node?.section?.spaceT || 8}
      spaceB={node?.section?.spaceB || 8}
      stackSpace={0}
      id={node?.section?.htmlID?.current}
      vRhythm={node?.section?.disableVRhythm ? false : true}
    >
      <TeaserSection node={node} />
    </Section>
  ),
);
