import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box } from '@lcifr/components/box';
import { Wrap as LuciferWrap } from '@lcifr/components/wrap';
import { Image as LuciferImage } from '@lcifr/components/image';
import { sx } from '@lcifr/sx';
import { Stack } from '@lcifr/components/stack';
import { Anchor } from '@lcifr/components/anchor';
import { LinkStudio } from '@lcifr/components/link';
import { Icon as LuciferIcon } from '@lcifr/components/icon';
import { Inline } from '@lcifr/components/inline';
import 'swiper/css';

export const Claim = sx(Box)({
  position: 'absolute',
  zIndex: 30,
  right: '100%',
  width: 112,
  display: 'flex',
  alignItems: 'center',
  bottom: 100,
  writingMode: 'vertical-rl',
  transform: 'scale(-1)',
  whiteSpace: 'nowrap',
  fontSize: '15px',
  fontWeight: 600,
  '@media (max-width: 1300px)': {
    display: 'none',
  },
  '@media (min-width: 1900px) and (max-width: 2075px)': {
    display: 'none',
  },
});

const sliderHeight = [
  'calc(100svh - 64px)',
  'calc(100svh - 40px - 64px)',
  'calc(100svh - 32px - 96px)',
  'calc(100svh - 30px - 96px)',
];

const sliderHeightFallback = sliderHeight.map((height) =>
  height.replace('svh', 'vh'),
);

export const Slider = sx(Box)({
  position: 'relative',
  height: sliderHeightFallback,
  '&': { height: sliderHeight },
  minHeight: ['auto', 'auto', 'auto', '800px'],
  width: '100%',
  maxWidth: '100vw',
  '.swiper': {
    overflow: 'initial !important',
  },
  '.swiper-wrapper': {
    position: 'relative',
    display: 'flex',
    flex: '1 0 auto',
    width: '100%',
    height: '100%',
  },
  '.swiper-slide': {
    position: 'relative',
    height: sliderHeightFallback,
    '&': { height: sliderHeight },
    width: [
      'calc(100% + 8px)',
      'calc(100% + 8px)',
      'calc(100% + 8px)',
      'calc(100vh - 30px)',
    ],
    minHeight: ['auto', 'auto', 'auto', '800px'],
    minWidth: ['auto', 'auto', 'auto', '800px'],
  },
});

export const Image = sx(LuciferImage)({
  position: 'relative',
  height: sliderHeightFallback,
  '&': { height: sliderHeight },
  width: ['100%', '75%', '75%', 'calc(100vh - 30px)'],
  minHeight: ['auto', 'auto', 'auto', '800px'],
  minWidth: ['auto', 'auto', 'auto', '800px'],
  objectFit: 'cover',
  verticalAlign: 'bottom',
});

export const Wrap = sx(LuciferWrap)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  zIndex: 20,
  height: '100%',
  padding: '0 !important',
  '&:before': {
    content: '""',
    position: 'absolute',
    bg: 'background',
    height: '100vh',
    top: 0,
    right: '100%',
    width: 'calc(100vw - 100% / 2)',
  },
});

export const Info = sx(Box)({
  position: 'absolute',
  zIndex: 5,
  bottom: ['0', '8px', '8px', 100],
  right: ['-8px', '-8px', '-8px', 0],
  padding: [
    '24px 28px 24px 32px',
    '24px 40px 28px 40px',
    '24px 40px 28px 40px',
    '48px 80px 56px 80px',
  ],
  width: ['100%', '100%', '100%', '45%'],
  backgroundColor: 'rgb(255 255 255 / 50%)',
  backdropFilter: 'blur(10px) brightness(1.08)',
  userSelect: 'none',
});

export const Icon = sx(LuciferIcon)({
  width: 18,
  height: 24,
  paddingLeft: '3px',
  paddingRight: '3px',
  transition: 'all 0.2s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    width: 24,
    height: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  svg: {
    height: 'inherit',
    width: 'inherit',
  },
});

export const InfoHeadline = sx('h2')({
  fontSize: ['14.5px', '18px', '24px', '30px'],
  lineHeight: 1.2,
  letterSpacing: ['-0.7px', '-0.7px', '-0.7px', '-1.3px'],
  fontWeight: 500,
});

export const Bar = sx(Box)(({ state }) => ({
  position: 'relative',
  width: [60, 60, 60, 100],
  height: 1,
  bg: 'white',
  mx: 3,
  '&:after': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    bg: 'text',
    width: `${state}%`,
    height: '100%',
    transition: 'width 0.3s ease-out',
  },
}));

type HeroSliderProps = {
  slides: any;
  claim: string;
};

export function HeroSlider({ slides, claim }: HeroSliderProps) {
  const [swipe, setSwipe] = React.useState<any>();
  const [realIndex, setRealIndex] = React.useState<number>(0);
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  return (
    <Box sx={{ width: '100vw', overflow: 'hidden', marginTop: [64, 64, 96] }}>
      <LuciferWrap>
        <Slider>
          {claim && <Claim>{claim}</Claim>}
          <Swiper
            onBeforeInit={(swiper) => {
              setSwipe(swiper);
            }}
            onInit={(swiper) => {
              setActiveIndex(swiper.activeIndex);
            }}
            onSlideChange={(swiper) => {
              setRealIndex(swiper.realIndex);
            }}
            slidesPerView="auto"
            preloadImages
            loop
          >
            {slides.map((slide, i) => (
              <SwiperSlide key={'slide-' + i}>
                <Image image={slide.image} alt="" priority />
              </SwiperSlide>
            ))}
          </Swiper>
          <Wrap width={'full'}>
            <Info>
              <Stack space={[3, 4]}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ fontSize: '13px', fontWeight: 600 }}>
                      {(realIndex + 1).toString().padStart(2, '0')}
                    </Box>
                    <Bar state={((realIndex + 1) / activeIndex) * 100} />
                    <Box sx={{ fontSize: '13px', fontWeight: 400 }}>
                      {activeIndex.toString().padStart(2, '0')}
                    </Box>
                  </Box>
                  <Inline gap={6}>
                    <Icon onClick={() => swipe.slidePrev()} name="chev-left" />
                    <Icon onClick={() => swipe.slideNext()} name="chev-right" />
                  </Inline>
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateAreas: 'a',
                    '& > *': { gridArea: 'a' },
                  }}
                >
                  {slides.map((slide, i) => (
                    <Box
                      key={'info-' + i}
                      sx={{
                        opacity: i === realIndex ? 1 : 0,
                        transition: 'opacity 0.2s ease-out',
                        pointerEvents: i === realIndex ? undefined : 'none',
                      }}
                      aria-hidden={i !== realIndex}
                    >
                      <Stack
                        space={[3, 4]}
                        sx={{ height: '100%', justifyContent: 'space-between' }}
                      >
                        <InfoHeadline>{slide.text}</InfoHeadline>
                        {slide.linkText && slide.link ? (
                          <Inline>
                            <LinkStudio link={slide.link}>
                              <Anchor variant="buttonSmallPrimary">
                                {slide.linkText}
                              </Anchor>
                            </LinkStudio>
                          </Inline>
                        ) : null}
                      </Stack>
                    </Box>
                  ))}
                </Box>
              </Stack>
            </Info>
          </Wrap>
        </Slider>
      </LuciferWrap>
    </Box>
  );
}
