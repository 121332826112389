import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { sx } from '@lcifr/sx';
import { Stack } from '@lcifr/components/stack';
import { Button } from '@lcifr/components/button';
import { LinkStudio } from '@lcifr/components/link';
import { Image as ImageWrapper } from 'components/Image';
import { Inline } from '@lcifr/components/inline';

export const Tile = sx(Box)({
  display: 'block',
  position: 'relative',
  bg: '#F4F7FF',
  pt: '75%',
  color: 'primary',
  overflow: 'hidden',
  cursor: 'pointer',
  '.TileProductButton.TileProductButton': {
    transition: 'all 0.2s ease-out',
    p: ['8px 24px', '14px 36px', '14px 36px'],
    pointerEvents: 'none',
  },
  '&:hover': {
    '.TileProductContent': {
      transform: 'scale(0.96)',
    },
    '.TileProductImage': {
      transform: 'scale(1.03)',
    },
    '.TileProductButton': {
      bg: 'primary',
      borderColor: 'primary',
      color: 'text.inverted',
      '&:hover': {
        transform: 'initial',
      },
    },
  },
});

export const Content = sx(Stack)({
  position: 'absolute',
  top: '15%',
  left: '10%',
  transition: 'transform 0.3s ease-out',
  transformOrigin: 'top left',
});

export const Image = sx(ImageWrapper)({
  position: 'absolute',
  bottom: 0,
  right: '-50%',
  height: '100%',
  width: '100%',
  transition: 'transform 0.3s ease-out',
  transformOrigin: 'center right',
});

type TeaserProductProps = {
  headline: string;
  link: any;
  linkText: string;
  image: any;
};

export function TeaserProduct({
  headline,
  link,
  linkText,
  image,
}: TeaserProductProps) {
  return (
    <LinkStudio link={link}>
      <Tile>
        <Image
          image={image}
          className="TileProductImage"
          alt="Teaser Visualisation"
        />
        <Content space={[2, 2, 5]} className="TileProductContent">
          {headline && (
            <Box
              sx={{
                fontSize: [32, 32, 42],
                lineHeight: '44px',
                fontWeight: 500,
                letterSpacing: '-2px',
                maxWidth: 240,
              }}
            >
              {headline}
            </Box>
          )}
          {linkText && (
            <Inline>
              <Button className="TileProductButton" variant="ghostPrimary">
                {linkText}
              </Button>
            </Inline>
          )}
        </Content>
      </Tile>
    </LinkStudio>
  );
}
