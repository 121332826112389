import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { sx } from '@lcifr/sx';
import { Heading } from '@lcifr/components/heading';
import { Icon } from '@lcifr/components/icon';
import { Video as MuxVideo } from 'components/Video';
import { useTimeout } from 'usehooks-ts';

export const VideoContainer = (sx as any)(MuxVideo)({
  position: 'absolute',
  zIndex: 8,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  objectFit: 'cover',
  '& > div': { height: '100%' },
  '& video': {
    height: '100%',
    width: '100%',
  },
});

function openFullscreen(elem) {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  }
}

type VideoProps = {
  videoId: any;
  headline?: any;
  itemsLength?: any;
};

export function Video({ videoId, headline, itemsLength }: VideoProps) {
  const [isActive, setIsActive] = React.useState(false);
  const [showControls, setShowControls] = React.useState(false);
  const setShowControlsIfActive = React.useCallback(() => {
    if (isActive) setShowControls(true);
  }, [isActive]);
  useTimeout(setShowControlsIfActive, 5000);

  const videoRef = React.useRef();
  // const videoHlsConfig = (videoRef as any)?.current?.hls?.config;
  // videoHlsConfig.startLevel = 4;
  const videoElement = (videoRef as any)?.current?.video?.current;

  React.useEffect(() => {
    if (isActive && videoElement) {
      openFullscreen(videoElement);
    }
  }, [isActive, videoElement]);

  const handleFullscreen = () => {
    if (videoElement === document.fullscreenElement) {
      // enter fullscreen
      // videoElement.currentTime = 0;
      videoElement.muted = false;
      videoElement.play();
    } else {
      // exit fullscreen
      videoElement.muted = true;
      setIsActive(false);
      videoElement.pause();
    }
  };

  React.useEffect(() => {
    const videoElement = (videoRef as any)?.current?.video?.current;
    videoElement?.addEventListener('fullscreenchange', handleFullscreen);

    return () => {
      videoElement?.removeEventListener('fullscreenchange', handleFullscreen);
    };
  });
  return videoId ? (
    <Box
      sx={{
        position: 'relative',
        pt: ['56.25%', '56.25%', '45%'],
        width: ['100%', '100%', 'calc(33.33333% - 27px)'],
        flexBasis: ['100%', '100%', 'calc(33.33333% - 27px)'],
        flexGrow: itemsLength >= 3 ? 0 : 1,
        borderRadius: '10px',
        overflow: 'hidden',
        cursor: 'pointer',
        '&:before': {
          content: '""',
          position: 'absolute',
          zIndex: 9,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background:
            'linear-gradient(180deg, rgba(15, 17, 37, 0.7) 0%, rgba(15, 17, 37, 0.07) 100%)',
        },
      }}
      onClick={() => {
        setIsActive(true);
      }}
    >
      <VideoContainer
        ref={videoRef}
        videoAssetId={videoId}
        showControls={showControls}
        loop
        muted
      />
      <Heading
        variant="h4"
        sx={{
          position: 'absolute',
          top: '20%',
          left: '50%',
          width: '100%',
          px: '20%',
          transform: 'translateX(-50%)',
          color: 'text.inverted',
          boxSizing: 'border-box',
          textAlign: 'center',
          display: 'flex',
          gap: 36,
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 10,
        }}
      >
        {headline}
        <Icon
          sx={{
            p: ['26px', '26px', '32px'],
            bg: 'primary',
            color: 'text.inverted',
            borderRadius: '50%',
            width: ['64px', '64px', '72px'],
            height: ['64px', '64px', '72px'],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxSizing: 'border-box',
          }}
          name="play"
        />
      </Heading>
    </Box>
  ) : null;
}
