import { Box } from '@lcifr/components/box';
import { Heading } from '@lcifr/components/heading';
import { Icon } from '@lcifr/components/icon';
import { Stack } from '@lcifr/components/stack';
import { Text } from '@lcifr/components/text';
import { Image } from 'components/Image';

type QuoteExtendedProps = {
  quote: any;
  source?: any;
  image?: any;
};

export function QuoteExtended({ quote, source, image }: QuoteExtendedProps) {
  return (
    <Stack space={4}>
      <Icon
        name="slate"
        sx={{
          width: '56px',
          height: '56px',
          svg: { width: '100%', height: '100%' },
        }}
      />
      <Heading variant="h2" as="h1" sx={{ width: '70%' }}>
        {quote}
      </Heading>
      <Box sx={{ display: 'flex', gap: 20, alignItems: 'center' }}>
        <Image
          sx={{
            position: 'relative',
            width: 48,
            height: 48,
            borderRadius: '50%',
            overflow: 'hidden',
          }}
          image={image}
          alt=""
        />
        <Text sx={{ fontWeight: 500 }}>{source}</Text>
      </Box>
    </Stack>
  );
}
