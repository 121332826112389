import { useRawI18n } from '@lcifr/pages';
import * as portable from '@lcifr/portable';
import { DownloadSection } from './DownloadSection';

export const PortableDownloadSectionType = portable.createType(
  'downloadSection',
  ({ node, context }) => {
    const i18n = useRawI18n();
    return (
      <>
        <DownloadSection
          width={node?.section?.width || context.defaultWrapWidth}
          minHeight={node?.section?.minHeight}
          bg={node?.section?.bg}
          bgImage={node?.section?.bgImage}
          spaceT={node?.section?.spaceT || '0px'}
          spaceB={node?.section?.spaceB || '0px'}
          stackSpace={node?.section?.stackSpace || 1}
          id={node?.section?.htmlID?.current}
          vRhythm={node?.section?.disableVRhythm ? false : true}
          downloadList={node?.downloadList}
          headline={node?.headline || i18n?.downloads?.headline}
          hideButton={node?.hideButton}
        />
        {/* <pre>{JSON.stringify(node, null, 2)}</pre> */}
      </>
    );
  },
);
