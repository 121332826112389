import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { sx } from '@lcifr/sx';
import { Heading } from '@lcifr/components/heading';
import { Button } from '@lcifr/components/button';
import { Stack } from '@lcifr/components/stack';
import { LinkStudio } from '@lcifr/components/link';
import { Section } from '@lcifr/components/section';
import { usePortableNodes } from '@lcifr/components/portable-nodes';
import { Inline } from '@lcifr/components/inline';
import { CollectionType } from '@lcifr/components/collection';
import { alpha } from '@lcifr/theming';
import { useRawI18n } from '@lcifr/pages';

export const Tab = sx(Stack)(({ isActive }) => ({
  // opacity: isActive ? 0 : 1,
  transition: '0.2s all ease-out',
  borderBottom: '1px solid',
  borderColor: alpha('text', 0.1),
  pb: 5,
  '&:last-of-type': {
    borderBottom: 'none',
  },
}));

export const TabContent = sx(Box)(({ isActive }) => ({
  opacity: isActive ? 1 : 0,
  height: !isActive && 0,
  pointerEvents: isActive ? 'auto' : 'none',
  mt: !isActive && '0 !important',
  transition: '0.2s height ease-out',
}));

export const IconBox = sx(Box)(({ isActive }) => ({
  position: 'relative',
  width: '32px',
  height: '32px',
  ml: 4,
  bg: isActive ? 'primary' : 'secondary',
  borderRadius: 10,
  flexShrink: 0,
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 12,
    height: 1,
    bg: 'background',
    transform: 'translate(-50%,-50%)',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    opacity: isActive ? 0 : 1,
    top: '50%',
    left: '50%',
    width: 1,
    height: 12,
    bg: 'background',
    transform: 'translate(-50%,-50%)',
  },
}));

type FaqProps = {
  headline?: any;
  width?: any;
  minHeight?: any;
  bg?: any;
  bgImage?: any;
  spaceT?: any;
  spaceB?: any;
  stackSpace?: any;
  id?: any;
  vRhythm?: any;
  content?: any;
  faqList: any;
  hasLink?: any;
};

export function Faq({
  headline,
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
  faqList,
  hasLink,
}: FaqProps) {
  const PortableNodes = usePortableNodes();
  const i18n = useRawI18n();
  const link = i18n?.faq?.link;
  const linkText = i18n?.faq?.linkText;
  const siteConfigHeadline = i18n?.faq?.headline;
  const [activeTab, setActiveTab] = React.useState(0);
  return faqList ? (
    <Section
      width={width}
      minHeight={minHeight}
      bg={bg}
      bgImage={bgImage}
      spaceT={spaceT || 8}
      spaceB={spaceB || 8}
      stackSpace={0}
      id={id}
      vRhythm={vRhythm}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '1fr 2fr'],
          gap: 40,
        }}
      >
        <Stack space={4} sx={{ pr: 4 }}>
          <Heading variant="h2">{headline || siteConfigHeadline}</Heading>
          {hasLink && link && linkText && (
            <Inline>
              <LinkStudio link={link}>
                <Button>{linkText}</Button>
              </LinkStudio>
            </Inline>
          )}
        </Stack>
        <Stack space={5}>
          <CollectionType node={faqList}>
            {(faqs) => {
              return (
                <>
                  {faqs.map((faq, i) => (
                    <Tab space={3} key={`faq-${i}`}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setActiveTab(i);
                        }}
                      >
                        <Heading variant="h5">{(faq as any)?.headline}</Heading>
                        <IconBox isActive={i === activeTab} />
                      </Box>
                      <TabContent isActive={i === activeTab}>
                        <PortableNodes blocks={(faq as any)?.content}>
                          {(children) => (
                            <Stack space={stackSpace}>{children}</Stack>
                          )}
                        </PortableNodes>
                      </TabContent>
                    </Tab>
                  ))}
                </>
              );
            }}
          </CollectionType>
        </Stack>
      </Box>
    </Section>
  ) : null;
}
