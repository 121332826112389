import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { sx } from '@lcifr/sx';
import { Heading } from '@lcifr/components/heading';
import { Stack } from '@lcifr/components/stack';
import { Section } from '@lcifr/components/section';
import { usePortableNodes } from '@lcifr/components/portable-nodes';
import { alpha } from '@lcifr/theming';
import { Text } from '@lcifr/components/text';

export const Table = sx('table')({
  borderCollapse: 'collapse',
  borderSpacing: 0,
  width: '100%',
});

export const Tr = sx('tr')({
  '&:last-of-type': {
    border: 0,
  },
});

export const Td = sx('td')({
  py: 1,
  px: 2,
});

type InfoSectionProps = {
  width?: any;
  minHeight?: any;
  bg?: any;
  bgImage?: any;
  spaceT?: any;
  spaceB?: any;
  stackSpace?: any;
  id?: any;
  vRhythm?: any;
  content?: any;
  infoList: any;
  isBoxed: any;
};

export function InfoSection({
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
  infoList,
  isBoxed,
  content,
}: InfoSectionProps) {
  const PortableNodes = usePortableNodes();
  return infoList ? (
    <Section
      width={width}
      minHeight={minHeight}
      bg={bg}
      bgImage={bgImage}
      spaceT={spaceT || 8}
      spaceB={spaceB || 8}
      stackSpace={stackSpace}
      id={id}
      vRhythm={vRhythm}
    >
      <PortableNodes blocks={content}>
        {(children) => <Stack space={stackSpace}>{children}</Stack>}
      </PortableNodes>
      <Box>
        {infoList.map((item, i) => (
          <Box
            key={`item-${i}`}
            sx={{
              position: 'relative',
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr', '1fr 2fr'],
              gap: ['8px', '8px', 40],
              bg: isBoxed ? 'light' : null,
              p: isBoxed ? [4, 4, 8] : null,
              pb: stackSpace || 8,
              mb: isBoxed ? null : stackSpace || 8,
              '&:last-of-type': {
                mb: 0,
                '&:before': {
                  display: 'none',
                },
              },
              '&:before': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                bg: alpha('text', 0.1),
                width: isBoxed
                  ? [
                      'calc(100% - 48px)',
                      'calc(100% - 48px)',
                      'calc(100% - 156px)',
                    ]
                  : '100%',
                left: '50%',
                transform: 'translateX(-50%)',
                height: '1px',
              },
            }}
          >
            <Stack space={4} sx={{ pr: 4 }}>
              <Heading variant="h4">{item?.headline}</Heading>
            </Stack>

            {item?._type === 'itemContent' ? (
              <PortableNodes blocks={item?.content}>
                {(children) => <Stack space={4}>{children}</Stack>}
              </PortableNodes>
            ) : item?._type === 'itemTable' ? (
              <Box sx={{ overflowX: 'auto' }}>
                <Table>
                  <tbody>
                    {item.sizeChart.rows.map((row, i) => (
                      <Tr
                        key={`tr-${i}`}
                        sx={{
                          borderBottom: i === 0 ? 0 : '1px solid',
                          borderColor: alpha('text', 0.1),
                        }}
                      >
                        {row.cells.map((cell, j) => (
                          <Td
                            key={`td-${j}`}
                            sx={{
                              py: i === 1 || i === 0 ? 0 : 2,
                              pb: i === 1 && 2,
                              whiteSpace: (i === 1 || i === 0) && 'nowrap',
                            }}
                          >
                            {i === 0 ? (
                              <Heading variant="h6">{cell}</Heading>
                            ) : i === 1 ? (
                              <Text sx={{ fontSize: '12px' }}>{cell}</Text>
                            ) : (
                              <Text>{cell}</Text>
                            )}
                          </Td>
                        ))}
                      </Tr>
                    ))}
                  </tbody>
                </Table>
              </Box>
            ) : null}
          </Box>
        ))}
      </Box>
    </Section>
  ) : null;
}
