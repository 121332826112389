import * as React from 'react';
import { Section } from '@lcifr/components/section';
import { Box } from '@lcifr/components/box';
import { Stack } from '@lcifr/components/stack';
import { CenterY } from '@lcifr/components/center';
import { CollectionType } from '@lcifr/components/collection';
import { usePortableNodes } from '@lcifr/components/portable-nodes';
import { Video } from 'components/Video';
import Link from 'next/link';
import { sx } from '@lcifr/sx';
import { Anchor } from '@lcifr/components/anchor';
import { Icon } from '@lcifr/components/icon';

export const List = sx('ul')({
  listStyle: 'none',
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '20px',
  mt: 2,
  mb: 2,
});

export const ListItem = sx('li')({
  marginBottom: 2,
});

export const ListAnchor = sx(Anchor)({
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  marginBottom: 2,
  transition: '0.2s all ease-out',
  '&:hover': {
    color: 'primary',
  },
});

type ProcessSectionProps = {
  stackSpace?: any;
  width?: any;
  minHeight?: any;
  bg?: any;
  bgImage?: any;
  spaceT?: any;
  spaceB?: any;
  id?: any;
  vRhythm?: any;
  isBoxed: any;
  isReversed: any;
  video?: any;
  content?: any;
  productList?: any;
};

export function ProcessSection({
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
  isBoxed,
  isReversed,
  video,
  content,
  productList,
}: ProcessSectionProps) {
  const PortableNodes = usePortableNodes();
  return (
    <Section
      width={width}
      minHeight={minHeight}
      bg={bg}
      bgImage={bgImage}
      spaceT={spaceT || null}
      spaceB={spaceB || 8}
      stackSpace={stackSpace}
      id={id}
      vRhythm={vRhythm}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: [
            '1fr',
            '1fr',
            isReversed ? '60% 40%' : '40% 60%',
          ],
          gap: [20, 20, 80],
          width: ['100%', '100%', 'calc(100% - 80px)'],
          bg: isBoxed && 'light',
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            boxSizing: 'border-box',
            bg: isBoxed && 'light',
            p: isBoxed
              ? isReversed
                ? ['20px', '20px', '80px 80px 80px 0']
                : ['20px', '20px', '80px 0 80px 80px']
              : [null, null, '80px 0'],
          }}
        >
          <CenterY>
            <Stack space={stackSpace}>
              {content && (
                <PortableNodes blocks={content}>
                  {(children) => <Stack space={stackSpace}>{children}</Stack>}
                </PortableNodes>
              )}

              <CollectionType node={productList}>
                {(products) => {
                  return (
                    <List>
                      {products.map((product, i) => (
                        <ListItem key={`product-${i}`}>
                          <Link
                            key={`product-${i}`}
                            href={product?.slug ?? '#'}
                            locale={product?.locale}
                            passHref
                            legacyBehavior
                          >
                            <ListAnchor>
                              <Icon name="chev-right" />
                              {(product as any)?.name}
                            </ListAnchor>
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  );
                }}
              </CollectionType>
            </Stack>
          </CenterY>
        </Box>
        <Box sx={{ order: [-1, -1, isReversed ? -1 : 1] }}>
          <Box sx={{ pt: '100%', position: 'relative' }}>
            <Video
              videoAssetId={video?.asset?._ref}
              showControls={false}
              autoplay
              loop
              muted
              poster={false}
            />
          </Box>
        </Box>
      </Box>
    </Section>
  );
}
