import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { sx } from '@lcifr/sx';
import { Stack } from '@lcifr/components/stack';
import { Button } from '@lcifr/components/button';
import { Icon as LuciferIcon } from '@lcifr/components/icon';
import { LinkStudio } from '@lcifr/components/link';
import { Inline } from '@lcifr/components/inline';

export const Tile = sx(Box)({
  display: 'block',
  position: 'relative',
  bg: 'text',
  pt: '75%',
  color: 'text.inverted',
  overflow: 'hidden',
  cursor: 'pointer',
  '.TileProcessButton.TileProcessButton': {
    transition: 'all 0.2s ease-out',
    p: ['8px 24px', '14px 36px', '14px 36px'],
    pointerEvents: 'none',
  },
  '&:hover': {
    '.TileProcessContent': {
      transform: 'scale(0.96)',
    },
    '.TileProcessButton': {
      bg: 'background',
      borderColor: 'background',
      color: 'text',
      '&:hover': {
        transform: 'initial',
      },
    },
    '.TileProcessProcess': {
      transform: 'scale(0.93)',
    },
    '.TileProcessGlow': {
      opacity: 0.7,
      height: '60%',
      bottom: '-30%',
      transform: 'rotate(-13deg) translateY(-20%)',
    },
  },
});

export const Content = sx(Stack)({
  position: 'absolute',
  top: '10%',
  left: '10%',
  transition: 'transform 0.3s ease-out',
  transformOrigin: 'top left',
});

export const Card = sx(Box)({
  position: 'absolute',
  bottom: '-8%',
  right: '15%',
  height: '60%',
  width: '35%',
  bg: 'background',
  boxShadow: '4px 4px 4px rgba(0,0,0,0.02)',
  borderRadius: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.3s ease-out',
  transformOrigin: 'center right',
});

export const Icon = sx(LuciferIcon)({
  width: '80%',
  height: '80%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    height: 'inherit',
    width: 'inherit',
  },
});

export const Process = sx(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  transform: 'scale(0.9)',
  transition: 'transform 0.3s ease-out',
  transformOrigin: 'center bottom',
});

export const Glow = sx(Box)({
  bg: 'transparent',
  position: 'absolute',
  left: '-5%',
  bottom: '-10%',
  height: '40%',
  width: '180%',
  background:
    'radial-gradient(50% 50% at 50% 50%, rgba(0, 71, 255, 1) 0%, rgba(111, 44, 255, 0) 100%)',
  transform: 'rotate(-10deg) translateY(0)',
  opacity: 0.4,
  transition: 'all 0.3s ease-out',
});

export const BubbleOne = sx(LuciferIcon)({
  position: 'absolute',
  bottom: '-5%',
  left: '17.5%',
  borderRadius: '100%',
  height: 0,
  width: '13%',
  paddingTop: '13%',
  bg: 'background',
  transform: 'translate(-50%,-50%)',
  svg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    height: '45%',
    width: '45%',
    color: 'text',
  },
});

export const BubbleTwo = sx(LuciferIcon)({
  position: 'absolute',
  bottom: 0,
  left: '41%',
  borderRadius: '100%',
  height: 0,
  width: '17%',
  paddingTop: '17%',
  bg: 'background',
  transform: 'translate(-50%,-50%)',
  svg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    height: '45%',
    width: '45%',
    color: 'text',
  },
});

export const BubbleThree = sx(LuciferIcon)({
  position: 'absolute',
  bottom: 0,
  left: '65%',
  borderRadius: '100%',
  height: 0,
  width: '12%',
  paddingTop: '12%',
  bg: 'background',
  transform: 'translate(-50%,-50%)',
  svg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    height: '45%',
    width: '45%',
    color: 'text',
  },
});

export const BubbleFour = sx(LuciferIcon)({
  position: 'absolute',
  bottom: 0,
  left: '90%',
  borderRadius: '100%',
  height: 0,
  width: '25%',
  paddingTop: '25%',
  bg: 'primary',
  transform: 'translate(-50%,-50%)',
  svg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    height: '50%',
    width: '50%',
    color: 'text.inverted',
  },
});

type TeaserProcessProps = {
  headline: string;
  link: any;
  linkText: string;
};

export function TeaserProcess({
  headline,
  link,
  linkText,
}: TeaserProcessProps) {
  return (
    <LinkStudio link={link}>
      <Tile>
        <Process className="TileProcessProcess">
          <Glow className="TileProcessGlow" />
          <BubbleOne name="path" />
          <BubbleTwo name="twinarrows" />
          <BubbleThree name="layers" />
          <BubbleFour name="maze" />
        </Process>
        <Content space={[2, 2, 5]} className="TileProcessContent">
          {headline && (
            <Box
              sx={{
                fontSize: [32, 32, 42],
                lineHeight: '44px',
                fontWeight: 500,
                letterSpacing: '-2px',
                maxWidth: 240,
              }}
            >
              {headline}
            </Box>
          )}
          {linkText && (
            <Inline>
              <Button className="TileProcessButton" variant="ghostBright">
                {linkText}
              </Button>
            </Inline>
          )}
        </Content>
      </Tile>
    </LinkStudio>
  );
}
