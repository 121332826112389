import { Box } from '@lcifr/components/box';
import * as React from 'react';
import { useAllVideosReady } from './VideoList.context';

export default function VideoListWithoutProvider({ children }) {
  const { allVideosReady } = useAllVideosReady();
  return (
    <Box
      sx={{
        transition: 'opacity 1s ease-out',
        // opacity: [1, 1, allVideosReady ? 1 : 0],
        opacity: 1,
      }}
    >
      {children}
    </Box>
  );
}
