import * as React from 'react';
import { Section } from '@lcifr/components/section';
import { Box } from '@lcifr/components/box';
import { CollectionType } from '@lcifr/components/collection';
import { Image as WrappedImage } from 'components/Image';
import { sx } from '@lcifr/sx';
import { Heading } from '@lcifr/components/heading';
import { alpha } from '@lcifr/theming';
import Link from 'next/link';
import { Stack } from '@lcifr/components/stack';
import { Anchor } from '@lcifr/components/anchor';
import { useRouter } from 'next/router';

export const Image = sx(WrappedImage)({
  paddingTop: `${(3 / 5) * 100}%`,
  position: 'relative',
  overflow: 'hidden',
  background:
    'linear-gradient(180deg, rgba(15, 17, 37, 0.1) 0%, rgba(15, 17, 37, 0.03) 100%)',
  img: {
    objectFit: 'cover !important' as any,
  },
});

export const Category = sx(Box)({
  py: 8,
  borderTop: '1px solid',
  borderColor: alpha('text', 0.1),
  '&:first-of-type': {
    border: 0,
    pt: 0,
  },
  '&:last-of-type': {
    pb: 0,
  },
});

export const Tile = sx(Anchor)({
  img: {
    transition: 'all ease-out 0.4s !important',
  },
  '&:hover': {
    img: {
      transform: 'scale(1.05)',
    },
  },
});

type ProductCategoryListProps = {
  stackSpace: any;
  width: any;
  minHeight: any;
  bg: any;
  bgImage: any;
  spaceT: any;
  spaceB: any;
  id: any;
  vRhythm: any;
  productCategories: any;
};

export function ProductCategoryList({
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
  productCategories,
}: ProductCategoryListProps) {
  const { locale } = useRouter();
  return (
    <Section
      width={width}
      minHeight={minHeight}
      bg={bg}
      bgImage={bgImage}
      spaceT={spaceT}
      spaceB={spaceB || 8}
      stackSpace={stackSpace}
      id={id}
      vRhythm={vRhythm}
    >
      <CollectionType node={productCategories}>
        {(categories) =>
          (categories as any).map((category, i) => {
            return (
              <Category key={`category-${i}`}>
                <Stack space={4}>
                  <Heading variant="h3" as="h1">
                    {category?.page?.title}
                  </Heading>
                  <CollectionType node={(category as any).products}>
                    {(products) => (
                      <Box
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr 1fr'],
                          gap: 40,
                          userSelect: 'none',
                        }}
                      >
                        {(products as any).map((product, j) => {
                          return (
                            <Link
                              href={`/${(product as any)?.slugs?.join('/')}`}
                              locale={locale}
                              passHref
                              legacyBehavior
                              key={`product-${i}-${j}`}
                            >
                              <Tile>
                                <Stack space={3}>
                                  <Image
                                    image={(product as any).image}
                                    alt=""
                                  />
                                  <Heading variant="h5" as="h2">
                                    {(product as any).name ||
                                      (product as any).title}
                                  </Heading>
                                </Stack>
                              </Tile>
                            </Link>
                          );
                        })}
                      </Box>
                    )}
                  </CollectionType>
                </Stack>
              </Category>
            );
          })
        }
      </CollectionType>
    </Section>
  );
}
