import { useEffect, useRef } from 'react';

export const useIntersectionObserver = ({
  root,
  target,
  onIntersect,
  threshold = 0,
  rootMargin = '0px',
}: {
  root?: React.MutableRefObject<Element | undefined>;
  target: React.MutableRefObject<Element | undefined>;
  onIntersect: IntersectionObserverCallback;
  threshold?: number | number[];
  rootMargin?: string;
}) => {
  const savedCallback = useRef<IntersectionObserverCallback>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = onIntersect;
  });

  useEffect(() => {
    function onIntersect(
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver,
    ) {
      if (savedCallback && savedCallback.current) {
        savedCallback.current(entries, observer);
      }
    }

    if (target && target.current) {
      const observer = new IntersectionObserver(onIntersect, {
        root: root ? root.current : null,
        rootMargin,
        threshold,
      });

      observer.observe(target.current);

      // Let's clean up after ourselves.
      return () => {
        if (target && target.current) {
          observer.unobserve(target.current);
        }
      };
    }
  }, [root, target, threshold, rootMargin]);
};
