import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { Wrap as LuciferWrap } from '@lcifr/components/wrap';
import { Image } from 'components/Image';
import { sx } from '@lcifr/sx';
import { Section } from '@lcifr/components/section';
import { Heading } from '@lcifr/components/heading';

export const Bg = sx(Box)(({ spaceT, spaceB }) => ({
  mt: spaceT,
  mb: spaceB,
  '> div > div': {
    pl: '0 !important',
    pr: '0 !important',
  },
  background:
    'linear-gradient(180deg, rgba(15, 17, 37, 0.1) 0%, rgba(15, 17, 37, 0.03) 100%)',
}));

export const Stage = sx(Box)({
  position: 'relative',
  height: ['auto', 'auto', '60vh'],
  minHeight: ['200px', '200px', '600px'],
  display: 'grid',
  gap: 40,
  pt: ['112px', '112px', '10%'],
  pb: ['8px', '8px', null],
  boxSizing: 'border-box',
  gridTemplateColumns: ['1fr', '1fr', '1fr 2fr'],
  alignItems: 'center',
});

type HeroGenericProps = {
  headline: any;
  image: any;
  width: any;
  minHeight: any;
  bg: any;
  bgImage: any;
  spaceT: any;
  spaceB: any;
  stackSpace: any;
  id: any;
  vRhythm: any;
};

export function HeroGeneric({
  headline,
  image,
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
}: HeroGenericProps) {
  return (
    <Bg spaceB={spaceB} spaceT={spaceT}>
      <Section
        width={width}
        minHeight={minHeight}
        bg={bg}
        bgImage={bgImage}
        spaceT={undefined}
        spaceB={undefined}
        stackSpace={stackSpace}
        id={id}
        vRhythm={vRhythm}
      >
        <LuciferWrap width="normal">
          <Stage>
            <Heading as="h1">{headline}</Heading>
            {image && (
              <Box
                sx={{
                  position: 'relative',
                  pt: '50%',
                  borderRadius: '10px',
                  overflow: 'hidden',
                }}
              >
                <Image image={image} alt="" />
              </Box>
            )}
          </Stage>
        </LuciferWrap>
      </Section>
    </Bg>
  );
}
