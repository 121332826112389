import * as portable from '@lcifr/portable';
import { ProductCategoryList } from './ProductCategoryList';

export const PortableProductCategoryListType = portable.createType(
  'productCategoryList',
  ({ node, context }) => {
    return (
      <ProductCategoryList
        width={node?.section?.width || context.defaultWrapWidth}
        minHeight={node?.section?.minHeight}
        bg={node?.section?.bg}
        bgImage={node?.section?.bgImage}
        spaceT={node?.section?.spaceT || 8}
        spaceB={node?.section?.spaceB || 8}
        stackSpace={node?.section?.stackSpace || 1}
        id={node?.section?.htmlID?.current}
        vRhythm={node?.section?.disableVRhythm ? false : true}
        productCategories={node?.productCategories}
      />
    );
  },
);
