import { createType } from '@lcifr/portable';
import { HeroCareer } from './HeroCareer';

export const PortableHeroCareerType = createType('heroCareer', ({ node }) => {
  return (
    <>
      <HeroCareer
        width={node?.section?.width}
        minHeight={node?.section?.minHeight}
        bg={node?.section?.bg}
        bgImage={node?.section?.bgImage}
        spaceT={node?.section?.spaceT || 0}
        spaceB={node?.section?.spaceB || 0}
        stackSpace={0}
        id={node?.section?.htmlID?.current}
        vRhythm={node?.section?.disableVRhythm ? false : true}
      />
      {/* <pre>{JSON.stringify(node, null, 2)}</pre> */}
    </>
  );
});
