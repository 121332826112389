import * as portable from '@lcifr/portable';
import { ProcessSection } from 'components/ProcessSection';

export const PortableProcessSectionType = portable.createType(
  'processSection',
  ({ node, context }) => {
    return (
      <ProcessSection
        width={node?.section?.width || context.defaultWrapWidth}
        minHeight={node?.section?.minHeight}
        bg={node?.section?.bg}
        bgImage={node?.section?.bgImage}
        spaceT={node?.section?.spaceT}
        spaceB={node?.section?.spaceB}
        content={node?.section?.blocks}
        stackSpace={node?.section?.stackSpace || 5}
        id={node?.section?.htmlID?.current}
        vRhythm={node?.section?.disableVRhythm ? false : true}
        video={node?.video}
        isReversed={node?.isReversed}
        isBoxed={node?.isBoxed}
        productList={node?.productList}
      />
    );
  },
);
