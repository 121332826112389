import * as React from 'react';
import { Heading } from '@lcifr/components/heading';
import { Image } from 'components/Image';
import { useRawI18n } from '@lcifr/pages';
import { Anchor } from '@lcifr/components/anchor';
import Link from 'next/link';

type ArticleProps = {
  image: any;
  headline?: any;
  itemsLength?: any;
  link: any;
};

export function Article({ image, headline, link, itemsLength }: ArticleProps) {
  const i18n = useRawI18n();
  const readmoreText = i18n?.newsSection?.readmoreText;
  return (
    <Link href={link} passHref legacyBehavior>
      <Anchor
        sx={{
          display: 'block',
          position: 'relative',
          pt: ['56.25%', '56.25%', '45%'],
          width: ['100%', '100%', 'calc(33.33333% - 27px)'],
          flexBasis: ['100%', '100%', 'calc(33.33333% - 27px)'],
          flexGrow: itemsLength >= 3 ? 0 : 1,
          borderRadius: '10px',
          overflow: 'hidden',
          cursor: 'pointer',
          img: {
            transition: '0.4s all ease-out',
          },
          '&:hover': {
            img: {
              transform: 'scale(1.03)',
            },
          },
          '&:before': {
            content: '""',
            position: 'absolute',
            zIndex: 9,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background:
              'linear-gradient(180deg, rgba(15, 17, 37, 0.7) 0%, rgba(15, 17, 37, 0.2) 100%)',
          },
        }}
      >
        <Image
          image={image}
          alt=""
          sx={{
            position: 'absolute',
            zIndex: 8,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            objectFit: 'cover',
            '& > div': { height: '100%' },
            '& video': {
              height: '100%',
              width: '100%',
            },
          }}
        />
        <Heading
          as="h1"
          variant="h4"
          sx={{
            position: 'absolute',
            top: '20%',
            left: 0,
            width: '100%',
            pl: '10%',
            pr: '15%',
            color: 'text.inverted',
            boxSizing: 'border-box',
            textAlign: 'left',
            display: 'flex',
            gap: 36,
            flexDirection: 'column',
            zIndex: 10,
          }}
        >
          {headline}
        </Heading>
        <Heading
          as="h2"
          variant="h6"
          sx={{
            position: 'absolute',
            bottom: '15%',
            left: 0,
            width: '100%',
            pl: '10%',
            pr: '15%',
            color: 'text.inverted',
            boxSizing: 'border-box',
            textAlign: 'left',
            display: 'flex',
            gap: 36,
            flexDirection: 'column',
            zIndex: 10,
            textDecoration: 'underline',
          }}
        >
          {readmoreText}
        </Heading>
      </Anchor>
    </Link>
  );
}
