import * as React from 'react';
import { CollectionType } from '@lcifr/components/collection';
import { Box } from '@lcifr/components/box';
import { TeaserNews } from 'components/TeaserNews';
import { TeaserProcess } from 'components/TeaserProcess';
import { TeaserProduct } from 'components/TeaserProduct';
import { TeaserProductCategory } from 'components/TeaserProductCategory';

type TeaserSectionProps = { node: any };

export function TeaserSection({ node }: TeaserSectionProps) {
  return (
    <CollectionType node={node?.teaserItems}>
      {(teaserItems) => (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
            gap: ['8px', '8px', 40],
            pt: ['8px', '8px', 40],
            userSelect: 'none',
          }}
        >
          {teaserItems.map((teaser) => {
            switch ((teaser as any).variant) {
              default:
              case 'teaserNews':
                return (
                  <TeaserNews
                    key={teaser._id}
                    headline={(teaser as any).headline}
                    link={(teaser as any).link}
                    linkText={(teaser as any).linkText}
                    image={(teaser as any).image}
                  />
                );
              case 'teaserProcess':
                return (
                  <TeaserProcess
                    key={teaser._id}
                    headline={(teaser as any).headline}
                    link={(teaser as any).link}
                    linkText={(teaser as any).linkText}
                  />
                );
              case 'teaserProduct':
                return (
                  <TeaserProduct
                    key={teaser._id}
                    headline={(teaser as any).headline}
                    link={(teaser as any).link}
                    linkText={(teaser as any).linkText}
                    image={(teaser as any).image}
                  />
                );
              case 'teaserProductCategory':
                return (
                  <TeaserProductCategory
                    key={teaser._id}
                    headline={(teaser as any).headline}
                    link={(teaser as any).link}
                    linkText={(teaser as any).linkText}
                    image={(teaser as any).image}
                  />
                );
            }
          })}
        </Box>
      )}
    </CollectionType>
  );
}
