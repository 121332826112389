import { useState } from 'react';
import { useIntersectionObserver } from './useIntersectionObserver';

export const useScrolledIntoView = ({
  retrigger = false,
  target,
  threshold,
  rootMargin,
  onChange,
}: {
  retrigger?: boolean;
  target: Parameters<typeof useIntersectionObserver>[0]["target"];
  threshold?: Parameters<typeof useIntersectionObserver>[0]["threshold"];
  rootMargin?: Parameters<typeof useIntersectionObserver>[0]["rootMargin"];
  onChange?: (isIntersecting: boolean) => void;
}) => {
  const [scrolledIntoView, setScrolledIntoView] = useState(false);

  function handleIntersect(entries: IntersectionObserverEntry[]) {
    const isIntersecting = entries[0].isIntersecting;
    if (retrigger) {
      setScrolledIntoView(isIntersecting);
      onChange && onChange(isIntersecting);
    } else if (!scrolledIntoView && isIntersecting) {
      setScrolledIntoView(true);
      onChange && onChange(isIntersecting);
    }
  }

  function reset() {
    setScrolledIntoView(false);
  }

  useIntersectionObserver({
    target,
    onIntersect: handleIntersect,
    threshold,
    rootMargin,
  });

  return [scrolledIntoView, reset] as const;
};
