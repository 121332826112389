import { Box } from '@lcifr/components/box';
import dynamic from 'next/dynamic';
import { useRef } from 'react';
import { useElementSize } from 'usehooks-ts';
import { useScrolledIntoView } from 'utils/hooks/useScrolledIntoView';
import { mergeRefs } from 'react-merge-refs';
import { GlobeProps } from './Globe';

const DynamicGlobe = dynamic<GlobeProps>(() => import('./Globe'), {
  ssr: false,
});

export function Globe({ locations }: Pick<GlobeProps, 'locations'>) {
  const [elementSizeRef, { width, height }] = useElementSize();
  const scrolledIntoViewRef = useRef();
  const [didScrollIntoView] = useScrolledIntoView({
    target: scrolledIntoViewRef,
  });

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        aspectRatio: '1',
        pointerEvents: 'none',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          // this is basically an overscan value for the globe that makes the canvas fill more of the screen
          inset: ['-90px', '-130px', '-160px', '-200px'],
        }}
        ref={mergeRefs([elementSizeRef, scrolledIntoViewRef])}
      >
        {didScrollIntoView && width && height ? (
          <DynamicGlobe width={width} height={height} locations={locations} />
        ) : null}
      </Box>
    </Box>
  );
}
