import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { Wrap as LuciferWrap } from '@lcifr/components/wrap';
import { Image } from 'components/Image';
import { sx } from '@lcifr/sx';
import { Heading } from '@lcifr/components/heading';
import { mapResponsiveValue, useTheme } from '@lcifr/theming';
import { Stack } from '@lcifr/components/stack';
import { Inline } from '@lcifr/components/inline';
import { Button } from '@lcifr/components/button';
import { useCollection, useRawI18n } from '@lcifr/pages';
import Link from 'next/link';

export const Bg = sx(Box)({
  '> div > div': {
    pl: '0 !important',
    pr: '0 !important',
  },
  background:
    'linear-gradient(180deg, rgba(15, 17, 37, 0.1) 0%, rgba(15, 17, 37, 0.03) 100%)',
});

export const Stage = sx(Box)({
  position: 'relative',
  height: ['auto', 'auto', '60vh'],
  minHeight: ['200px', '200px', '600px'],
  display: 'grid',
  gap: 40,
  pt: ['112px', '112px', '10%'],
  pb: ['8px', '8px', null],
  boxSizing: 'border-box',
  gridTemplateColumns: ['1fr', '1fr', '1fr 2fr'],
  alignItems: 'center',
});

type HeroNewsProps = {
  articleList?: any;
  width?: any;
  minHeight?: any;
  bg?: any;
  bgImage?: any;
  spaceT?: any;
  spaceB?: any;
  stackSpace?: any;
  id?: any;
  vRhythm?: any;
  pageId?: any;
  image?: any;
  headline?: any;
  isCompressed?: any;
};

export function HeroNews({
  articleList,
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
  pageId,
  image,
  headline,
  isCompressed,
}: HeroNewsProps) {
  const theme = useTheme();
  const i18n = useRawI18n();
  const article = useCollection(articleList);
  const readmoreText = i18n?.newsHero?.readmoreText;

  const [_isCompressed, setIsCompressed] = React.useState(false);

  React.useEffect(() => {
    if (isCompressed) {
      requestAnimationFrame(() => {
        setIsCompressed(true);
      });
    }
  }, [isCompressed]);

  return (
    <Box
      sx={{
        overflow: 'hidden',
        mb: [0, 0, _isCompressed ? '-160px' : null],
        transition: '0.5s margin ease-out',
      }}
    >
      <LuciferWrap>
        <Box sx={{ position: 'relative', paddingTop: ['100%', '100%', '65%'] }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          >
            <Box
              sx={{
                position: ['static', 'static', 'absolute'],
                top: 0,
                right: 0,
                height: '100%',
                width: (t) =>
                  mapResponsiveValue(t.wraps, (width, i) =>
                    i > 1
                      ? `calc(${width} + (100vw - ${width}) / 2)`
                      : undefined,
                  ),
                background:
                  'linear-gradient(180deg, rgba(15, 17, 37, 0.1) 0%, rgba(15, 17, 37, 0.03) 100%)',
              }}
            >
              <Image
                image={(article as any)?.[0]?.image || image}
                sx={{
                  height: '100%',
                  'span, img': {
                    position: ['static', 'static', 'absolute'],
                    top: 0,
                    right: 0,
                    width: (t) =>
                      mapResponsiveValue(t.wraps, (width, i) =>
                        i > 1
                          ? `calc(${width} + (100vw - ${width}) / 2) !important`
                          : undefined,
                      ),
                  },
                }}
                alt=""
              />
            </Box>
          </Box>

          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              right: ['-8px', '-8px', 0],
              pt: [4, 4, 6],
              pb: [2, 2, _isCompressed ? 10 : 6],
              pl: [5, 5, 8],
              pr: [5, 5, undefined],
              bg: 'background',
              width: ['95%', '95%', 'calc(66.6666% - 44px)'],
              textAlign: 'left',
              transition:
                '0.5s padding-top ease-out, 0.5s padding-bottom ease-out',
              '&:after': {
                content: '""',
                position: 'absolute',
                left: '100%',
                top: 0,
                py: 'inherit',
                width: mapResponsiveValue(theme.wraps, (width, i) =>
                  i > 1 ? `calc(((100vw - ${width}) / 2))` : 0,
                ),
                bg: 'background',
                height: '100%',
              },
            }}
          >
            <Stack space={3}>
              <Heading variant="h1" as="h1">
                {(article as any)?.[0]?.headline || headline}
              </Heading>
              {(article as any)?.[0]?.slug && (
                <Inline>
                  <Link
                    href={(article as any)?.[0]?.slug}
                    locale={(article as any)?.[0]?.locale}
                    passHref
                    legacyBehavior
                  >
                    <Button variant="small">{readmoreText}</Button>
                  </Link>
                </Inline>
              )}
            </Stack>
          </Box>
        </Box>
      </LuciferWrap>
    </Box>
  );
}
