import * as React from 'react';
import { PageProvider } from '@lcifr/pages';
import { AppProps } from 'next/app';
import Head from 'next/head';
import theme from '../theming/theme';
import { PortableNodes } from '@lcifr/components/lib/portable-nodes/component';
import { PortableSections } from '@lcifr/components/lib/portable-sections/component';
import { ListArrowMark } from 'components/ListArrow/ListArrow.portable';
import { BleachMark } from 'components/Bleach/Bleach.portable';
import { LeadMark } from 'components/Lead/Lead.portable';
import { IconMark } from 'components/Icon/Icon.portable';
import { PortableHeroSliderType } from 'components/HeroSlider/HeroSlider.portable';
import { PortableTeaserSectionType } from 'components/TeaserSection/TeaserSection.portable';
import { PortableMissionSectionType } from 'components/MissionSection/MissionSection.portable';
import { PortableImpressionSingleType } from 'components/ImpressionSingle/ImpressionSingle.portable';
import { PortableImpressionMultipleType } from 'components/ImpressionMultiple/ImpressionMultiple.portable';
import { PortableProductTechnologyListType } from 'components/ProductTechnologyList/ProductTechnologyList.portable';
import { PortableGlobeSectionType } from 'components/GlobeSection/GlobeSection.portable';
import { PortableHeroVideoType } from 'components/HeroVideo/HeroVideo.portable';
import { PortablePartnerType } from 'components/Partner/Partner.portable';
import { PortableServiceSectionType } from 'components/ServiceSection/ServiceSection.portable';
import { PortableCertificateSectionType } from 'components/CertificateSection/CertificateSection.portable';
import { PortableHeroProductsType } from 'components/HeroProducts/HeroProducts.portable';
import { PortableFormSectionType } from 'components/FormSection/FormSection.portable';
import { PortableProductCategoryListType } from 'components/ProductCategoryList/ProductCategoryList.portable';
import { PortableFaqType } from 'components/Faq/Faq.portable';
import { PortableInfoSectionType } from 'components/InfoSection/InfoSection.portable';
import { PortableVideoSectionType } from 'components/VideoSection/VideoSection.portable';
import { PortableHeroGenericType } from 'components/HeroGeneric/HeroGeneric.portable';
import { PortableRelatedProductsType } from 'components/RelatedProducts/RelatedProducts.portable';
import { PortableDownloadSectionType } from 'components/DownloadSection/DownloadSection.portable';
import { PortableNewsSectionType } from 'components/NewsSection/NewsSection.portable';
import { PortableHeroNewsType } from 'components/HeroNews/HeroNews.portable';
import { PortableHeroCareerType } from 'components/HeroCareer/HeroCareer.portable';
import { PortableTextMediaSectionType } from 'components/TextMediaSection/TextMediaSection.portable';
import { PortableQuoteExtendedType } from 'components/QuoteExtended/QuoteExtended.portable';
import { PortableHeroLandingType } from 'components/HeroLanding/HeroLanding.portable';
import { PortableProductFocusListType } from 'components/ProductFocusList/ProductFocusList.portable';
import { PortableSalespartnerSectionType } from 'components/SalespartnerSection/SalespartnerSection.portable';
import { PortableHeroProcessType } from 'components/HeroProcess/HeroProcess.portable';
import { PortableProcessSectionType } from 'components/ProcessSection/ProcessSection.portable';
import Link from 'next/link';
import Image from 'next/image';

PortableNodes.add(
  LeadMark,
  BleachMark,
  ListArrowMark,
  IconMark,
  PortableQuoteExtendedType,
);
PortableSections.add(
  PortableHeroSliderType,
  PortableTeaserSectionType,
  PortableMissionSectionType,
  PortableImpressionSingleType,
  PortableImpressionMultipleType,
  PortableProductTechnologyListType,
  PortableGlobeSectionType,
  PortableHeroVideoType,
  PortablePartnerType,
  PortableServiceSectionType,
  PortableCertificateSectionType,
  PortableHeroProductsType,
  PortableFormSectionType,
  PortableProductCategoryListType,
  PortableFaqType,
  PortableInfoSectionType,
  PortableHeroGenericType,
  PortableVideoSectionType,
  PortableRelatedProductsType,
  PortableDownloadSectionType,
  PortableNewsSectionType,
  PortableHeroNewsType,
  PortableHeroCareerType,
  PortableTextMediaSectionType,
  PortableHeroLandingType,
  PortableProductFocusListType,
  PortableSalespartnerSectionType,
  PortableHeroProcessType,
  PortableProcessSectionType,
);

export default function App({ Component, pageProps }: AppProps) {
  //  console.log(pageProps);
  return (
    <PageProvider pageProps={pageProps} theme={theme} Link={Link} Image={Image}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <Component {...pageProps} />
    </PageProvider>
  );
}
