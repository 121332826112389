import * as portable from '@lcifr/portable';
import { MissionSection } from './MissionSection';
import { Section } from '@lcifr/components/section';

export const PortableMissionSectionType = portable.createType(
  'missionSection',
  ({ node, context }) => (
    <Section
      width={node?.section?.width || context?.defaultWrapWidth}
      minHeight={node?.section?.minHeight}
      bg={node?.section?.bg}
      bgImage={node?.section?.bgImage}
      spaceT={node?.section?.spaceT || 8}
      spaceB={node?.section?.spaceB || 8}
      stackSpace={node?.section?.stackSpace || 5}
      id={node?.section?.htmlID?.current}
      vRhythm={node?.section?.disableVRhythm ? false : true}
    >
      <MissionSection
        content={node?.section?.blocks}
        link={node?.link}
        linkText={node?.linkText}
        personList={node?.personList}
        stackSpace={node?.section?.stackSpace || 5}
      />
    </Section>
  ),
);
