import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { Wrap as LuciferWrap } from '@lcifr/components/wrap';
import { Image } from 'components/Image';
import { sx } from '@lcifr/sx';
import { Anchor } from '@lcifr/components/anchor';
import Link from 'next/link';
import { Section } from '@lcifr/components/section';
import { Heading } from '@lcifr/components/heading';
import { useCollection, useRawI18n } from '@lcifr/pages';
import { Icon } from '@lcifr/components/icon';
import { Stack } from '@lcifr/components/stack';
import { Text } from '@lcifr/components/text';

export const Bg = sx(Box)({
  position: 'relative',
  '> div > div': {
    pl: '0 !important',
    pr: '0 !important',
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '80%',
    width: '100%',
    background:
      'linear-gradient(180deg, rgba(15, 17, 37, 0.1) 0%, rgba(15, 17, 37, 0.03) 100%)',
  },
});

type HeroCareerProps = {
  width: any;
  minHeight: any;
  bg: any;
  bgImage: any;
  spaceT: any;
  spaceB: any;
  stackSpace: any;
  id: any;
  vRhythm: any;
};

export function HeroCareer({
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
}: HeroCareerProps) {
  const i18n = useRawI18n();
  const headline = i18n?.careerHero?.headline;
  const image = i18n?.careerHero?.image;
  const readMore = i18n?.career?.cardReadMore;
  const jobCollection = (useCollection(i18n?.career?.jobList) ?? []).filter(
    ({ page }) => page.isActive !== false,
  );

  let layout = '1 / 5';
  switch (jobCollection?.length) {
    case 4:
    case 0:
      layout = '1 / 5';
      break;
    case 7:
    case 3:
      layout = '1 / 3';
      break;
    case 5:
    case 1:
      layout = '1 / 4';
      break;
    case 6:
    default:
      layout = '1 / 3';
      break;
  }

  return (
    <Bg
      sx={{
        pt: ['112px', '112px', '10%'],
        mb: spaceB || 8,
      }}
    >
      <Section
        width={width}
        minHeight={minHeight}
        bg={bg}
        bgImage={bgImage}
        spaceT={spaceT}
        stackSpace={stackSpace}
        id={id}
        vRhythm={vRhythm}
      >
        <LuciferWrap width="normal">
          <Heading sx={{ mb: 6 }} variant="h1" as="h1">
            {headline}
          </Heading>
          <Box
            sx={{
              position: 'relative',
              display: 'grid',
              gridTemplateColumns: [
                '1fr 1fr',
                '1fr 1fr',
                '1fr 1fr 1fr',
                '1fr 1fr 1fr 1fr',
              ],
              gap: ['8px', '8px', 40],
              pb: ['8px', '8px', null],
              boxSizing: 'border-box',
            }}
          >
            {image && (
              <Image
                sx={{
                  position: 'relative',
                  pt: ['50%', '50%', '25%'],
                  borderRadius: '10px',
                  overflow: 'hidden',
                  width: '100%',
                  gridColumn: layout,
                }}
                image={image}
                alt=""
              />
            )}
            {jobCollection?.map((job, i) => (
              <Stack
                space={4}
                key={`job-${i}`}
                sx={{
                  width: '100%',
                  boxSizing: 'border-box',
                  transition: '0.2s all ease-out',
                  p: 5,
                  bg: 'background',
                  borderRadius: '10px',
                  position: 'relative',
                  overflow: 'hidden',
                  gridColumn: ['span 2', null, 'auto'],
                  boxShadow: '4px 4px 4px rgba(0,0,0,0.02)',
                }}
              >
                <Icon
                  sx={{
                    p: ['20px', '20px', 4],
                    bg: 'primary',
                    color: 'text.inverted',
                    borderRadius: '50%',
                    width: ['64px', '64px', '72px'],
                    height: ['64px', '64px', '72px'],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxSizing: 'border-box',
                    svg: {
                      width: '100%',
                      height: '100%',
                    },
                    path: {
                      fill: 'currentColor !important',
                    },
                  }}
                  name={(job as any)?.icon?.variant}
                />
                <Heading variant="h5">{(job as any)?.headline}</Heading>
                <Text>{(job as any)?.summary}</Text>
                <Link
                  href={(job as any)?.slug}
                  locale={(job as any)?.locale}
                  passHref
                  legacyBehavior
                >
                  <Anchor variant="download">{readMore}</Anchor>
                </Link>
              </Stack>
            ))}
          </Box>
        </LuciferWrap>
      </Section>
    </Bg>
  );
}
