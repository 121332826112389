import { createType } from '@lcifr/portable';
import { VideoSection } from './VideoSection';

export const PortableVideoSectionType = createType(
  'videoSection',
  ({ node }) => {
    return (
      <>
        <VideoSection
          videoList={node?.videoList}
          width={node?.section?.width}
          minHeight={node?.section?.minHeight}
          bg={node?.section?.bg}
          bgImage={node?.section?.bgImage}
          spaceT={node?.section?.spaceT}
          spaceB={node?.section?.spaceB}
          stackSpace={0}
          id={node?.section?.htmlID?.current}
          vRhythm={node?.section?.disableVRhythm ? false : true}
        />
        {/* <pre>{JSON.stringify(node, null, 2)}</pre> */}
      </>
    );
  },
);
