import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { Wrap as LuciferWrap } from '@lcifr/components/wrap';
import { Image } from 'components/Image';
import { sx } from '@lcifr/sx';
import { Anchor } from '@lcifr/components/anchor';
import { CollectionType } from '@lcifr/components/collection';
import Link from 'next/link';

export const Stage = sx(Box)({
  position: 'relative',
  pt: [72, 72, 0],
  height: ['auto', 'auto', 'calc(100vh - 30px)'],
  minHeight: ['100vh', '100vh', '800px'],
  width: ['100%', '100%', 'calc(100% + (100vw - 100%) / 2)'],
  maxWidth: '1920px',
});

export const Items = sx(Box)({
  position: 'relative',
  display: 'grid',
  gridTemplateAreas: [
    `"${['one', 'two', 'three'].join('" "')}"`,
    `"${['one', 'two', 'three'].join('" "')}"`,
    `"${[
      'one one two',
      'one one two',
      'one one two',
      'one one three',
      'one one three',
    ].join('" "')}"`,
  ],
  width: '100%',
  height: '100%',
});

export const Item = sx(Anchor)(({ i }) => ({
  position: 'relative',
  gridArea: i === 0 ? 'one' : i === 1 ? 'two' : 'three',
  paddingTop: ['90%', '90%', 0],
  '&:before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    background:
      'linear-gradient(180deg, rgba(15,17,37,0.1) 0%, rgba(15,17,37,0.03) 100%)',
  },
  '&:hover': {
    '.heroProductsCaption': {
      transform: 'scale(1.04)',
    },
    '.heroProductsImage': {
      transform: 'scale(1.04)',
    },
  },
}));

export const Caption = sx(Box)({
  position: 'absolute',
  bottom: [4, 4, 7],
  right: [4, 4, 7],
  bg: 'background',
  borderRadius: 100,
  px: 6,
  py: '12px',
  fontSize: '13px',
  fontWeight: 600,
  boxShadow: '4px 4px 4px rgba(15, 17, 37, 0.02)',
  userSelect: 'none',
  transition: 'transform ease-out 0.3s',
});

export const Wrap = sx(LuciferWrap)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  zIndex: 20,
  height: '100%',
  padding: '0 !important',
  '&:before': {
    content: '""',
    position: 'absolute',
    bg: 'background',
    height: '100vh',
    top: 0,
    right: '100%',
    width: 'calc(100vw - 100% / 2)',
  },
});

type HeroProductsProps = {
  itemList: any;
};

export function HeroProducts({ itemList }: HeroProductsProps) {
  return (
    <LuciferWrap>
      <Stage>
        <CollectionType node={itemList}>
          {(items) => {
            return (
              <Items>
                {items.map((item, i) => (
                  <Link
                    key={'item-' + i}
                    href={(item as any).slug}
                    locale={(item as any).locale}
                    passHref
                    legacyBehavior
                  >
                    <Item i={i}>
                      <Image
                        sx={{
                          position: 'absolute',
                          left: 0,
                          bottom: 0,
                          height: '100%',
                          width: '100%',
                          verticalAlign: 'bottom',
                          transition: 'transform ease-out 0.3s',
                          img: {
                            objectFit: 'contain !important',
                          },
                        }}
                        className="heroProductsImage"
                        image={(item as any).imageCutout || (item as any).image}
                        alt={'item-' + i}
                      />
                      <Caption className="heroProductsCaption">
                        {(item as any).name}
                      </Caption>
                    </Item>
                  </Link>
                ))}
              </Items>
            );
          }}
        </CollectionType>
      </Stage>
    </LuciferWrap>
  );
}
