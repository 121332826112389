import * as React from 'react';
import * as portable from '@lcifr/portable';
import { FormSection } from './FormSection';

export const PortableFormSectionType = portable.createType(
  'formSection',
  ({ node, context }) => {
    return (
      <FormSection
        variant={node?.formVariant}
        width={node?.section?.width || context?.defaultWrapWidth}
        minHeight={node?.section?.minHeight}
        bg={node?.section?.bg}
        bgImage={node?.section?.bgImage}
        spaceT={node?.section?.spaceT || 0}
        spaceB={node?.section?.spaceB || 0}
        stackSpace={0}
        id={node?.section?.htmlID?.current}
        vRhythm={node?.section?.disableVRhythm ? false : true}
      />
    );
  },
);
