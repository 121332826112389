import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { sx } from '@lcifr/sx';
import { Heading } from '@lcifr/components/heading';
import { Button } from '@lcifr/components/button';
import { Stack } from '@lcifr/components/stack';
import { LinkStudio } from '@lcifr/components/link';
import { Section } from '@lcifr/components/section';
import { Inline } from '@lcifr/components/inline';
import { CollectionType } from '@lcifr/components/collection';
import { useRawI18n } from '@lcifr/pages';
import { Text } from '@lcifr/components/text';
import Link from 'next/link';
import { Anchor } from '@lcifr/components/anchor';
import { Icon } from '@lcifr/components/icon';
import { Wrap } from '@lcifr/components/wrap';

export const RemovePadding = sx(Box)({
  '> div > div': {
    pl: '0 !important',
    pr: '0 !important',
  },
});

export const Bg = sx(Box)({
  pt: 9,
  pb: 9,
  background:
    'linear-gradient(180deg, rgba(15, 17, 37, 0.1) 0%, rgba(15, 17, 37, 0.03) 100%)',
});

const getUrlFromId = (ref) => {
  if (!ref) return '';
  const [_file, id, extension] = ref.split('-');
  return `https://cdn.sanity.io/files/${process.env.NEXT_PUBLIC_STUDIO_PROJECT_ID}/${process.env.NEXT_PUBLIC_STUDIO_DATASET}/${id}.${extension}`;
};

type DownloadSectionProps = {
  stackSpace?: any;
  width?: any;
  minHeight?: any;
  bg?: any;
  bgImage?: any;
  spaceT?: any;
  spaceB?: any;
  id?: any;
  vRhythm?: any;
  downloadList: any;
  headline?: any;
  hideButton: any;
};

export function DownloadSection({
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
  downloadList,
  headline,
  hideButton,
}: DownloadSectionProps) {
  const i18n = useRawI18n();
  const downloadText = i18n?.downloads?.downloadText;
  const buttonTextMore = i18n?.downloads?.buttonTextMore;
  const buttonTextLess = i18n?.downloads?.buttonTextLess;
  const [limit, setLimit] = React.useState(3);
  return (
    <RemovePadding>
      <Section
        width={'full'}
        minHeight={minHeight}
        bg={bg}
        bgImage={bgImage}
        spaceT={spaceT || 8}
        spaceB={spaceB || 8}
        stackSpace={0}
        id={id}
        vRhythm={vRhythm}
      >
        <Bg>
          <Wrap>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr', '1fr 3fr'],
                gap: 40,
              }}
            >
              <Stack space={4}>
                <Heading variant="h2">{headline}</Heading>
                {!hideButton && (
                  <Inline>
                    <Button
                      onClick={() => {
                        limit === 3 ? setLimit(50) : setLimit(3);
                      }}
                      variant="bright"
                    >
                      {limit === 3 ? buttonTextMore : buttonTextLess}
                    </Button>
                  </Inline>
                )}
              </Stack>
              <CollectionType node={downloadList}>
                {(downloads) => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyItems: 'flex-end',
                        flexDirection: ['column', 'column', 'row'],
                        gap: ['8px', '8px', '40px'],
                        boxSizing: 'border-box',
                      }}
                    >
                      {downloads.map((download, i) =>
                        i <= limit - 1 ? (
                          <Stack
                            space={4}
                            key={`download-${i}`}
                            sx={{
                              transition: '0.2s all ease-out',
                              p: 5,
                              bg: 'background',
                              borderRadius: '10px',
                              position: 'relative',
                              width: ['100%', '100%', 'calc(33% - 26px)'],

                              flexBasis: ['auto', 'auto', 'calc(33% - 26px)'],
                              overflow: 'hidden',
                              boxSizing: 'border-box',
                            }}
                          >
                            <Icon
                              sx={{
                                p: ['20px', '20px', 4],
                                bg: 'primary',
                                color: 'text.inverted',
                                borderRadius: '50%',
                                width: ['64px', '64px', '72px'],
                                height: ['64px', '64px', '72px'],
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxSizing: 'border-box',
                                svg: {
                                  width: '100%',
                                  height: '100%',
                                },
                                path: {
                                  fill: 'currentColor !important',
                                },
                              }}
                              name={(download as any)?.icon?.variant}
                            />
                            <Heading variant="h5">
                              {(download as any)?.headline}
                            </Heading>
                            <Text>{(download as any)?.text}</Text>
                            <Link
                              key={(download as any)?._id}
                              href={getUrlFromId(
                                (download as any)?.file?.asset?._ref,
                              )}
                              passHref
                              legacyBehavior
                            >
                              <Anchor variant="download">
                                {(download as any)?.downloadText ||
                                  downloadText}
                              </Anchor>
                            </Link>
                          </Stack>
                        ) : null,
                      )}
                    </Box>
                  );
                }}
              </CollectionType>
            </Box>
          </Wrap>
        </Bg>
      </Section>
    </RemovePadding>
  );
}
