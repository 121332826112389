export function formatAsNumber(
  num: number,
  locales?: Intl.LocalesArgument,
  options?: Intl.NumberFormatOptions,
) {
  return num.toLocaleString(locales, {
    style: 'decimal',
    useGrouping: true,
    maximumFractionDigits: 4,
    ...options,
  });
}

export function formatAsPercent(
  num: number,
  locales?: Intl.LocalesArgument,
  options?: Intl.NumberFormatOptions,
) {
  return num.toLocaleString(locales, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
  });
}
