import * as React from 'react';
import { Section } from '@lcifr/components/section';
import { Box } from '@lcifr/components/box';
import { CollectionType } from '@lcifr/components/collection';
import { Image as WrappedImage } from 'components/Image';
import { sx } from '@lcifr/sx';
import { Heading } from '@lcifr/components/heading';
import { Anchor } from '@lcifr/components/anchor';
import { Button } from '@lcifr/components/button';
import { LinkStudio } from '@lcifr/components/link';
import { Stack } from '@lcifr/components/stack';
import Link from 'next/link';
import { Inline } from '@lcifr/components/inline';

export const Image = sx(WrappedImage)({
  paddingTop: `${(4 / 5) * 100}%`,
  position: 'relative',
  overflow: 'hidden',
  background:
    'linear-gradient(180deg, rgba(15, 17, 37, 0.1) 0%, rgba(15, 17, 37, 0.03) 100%)',
});

export const Tile = sx(Anchor)({
  // @ts-ignore
  img: {
    transition: 'all ease-out 0.4s !important',
    objectFit: 'cover !important',
  },
  '&:hover': {
    img: {
      transform: 'scale(1.05)',
    },
  },
});

type RelatedProductsProps = {
  stackSpace?: any;
  width?: any;
  minHeight?: any;
  bg?: any;
  bgImage?: any;
  spaceT?: any;
  spaceB?: any;
  id?: any;
  vRhythm?: any;
  productList: any;
  headline?: any;
  link: any;
  linkText: any;
  hideButton: any;
};

export function RelatedProducts({
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
  productList,
  headline,
  link,
  linkText,
  hideButton,
}: RelatedProductsProps) {
  return (
    <Section
      width={width}
      minHeight={minHeight}
      bg={bg}
      bgImage={bgImage}
      spaceT={spaceT}
      spaceB={spaceB || 8}
      stackSpace={stackSpace}
      id={id}
      vRhythm={vRhythm}
    >
      <Box
        sx={{
          display: 'grid',
          // https://stackoverflow.com/questions/55347359/why-is-css-grid-row-height-different-in-safari
          // safari fix ~DH
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gap: 40,
            gridTemplateColumns: ['1fr', '1fr', '2fr 1fr'],
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gap: ['8px', '8px', 40],
              gridTemplateColumns: ['1fr 1fr'],
            }}
          >
            <CollectionType node={productList}>
              {(products) => {
                // TODO: related products algo
                const _products = products
                  .filter(({ _id }) => !_id.startsWith('drafts.'))
                  .slice(0, 4);
                return (_products as any).map((product, i) => {
                  return (
                    <Link
                      href={product?.slug}
                      locale={product?.locale}
                      passHref
                      legacyBehavior
                      key={`category-${i}`}
                    >
                      <Tile>
                        <Image image={(product as any).image} alt="" />
                      </Tile>
                    </Link>
                  );
                });
              }}
            </CollectionType>
          </Box>
          <Box
            sx={{ height: '50%', display: 'flex', alignItems: 'center', pr: 2 }}
          >
            <Stack space={4}>
              {headline ? <Heading variant="h3">{headline}</Heading> : null}
              {hideButton ? null : (
                <Inline>
                  <LinkStudio link={link}>
                    <Button>{linkText}</Button>
                  </LinkStudio>
                </Inline>
              )}
            </Stack>
          </Box>
        </Box>
      </Box>
    </Section>
  );
}
