import * as portable from '@lcifr/portable';
import { GlobeSection } from 'components/GlobeSection';

export const PortableGlobeSectionType = portable.createType(
  'globeSection',
  ({ node, context }) => {
    return (
      <GlobeSection
        width={node?.section?.width || context.defaultWrapWidth}
        minHeight={node?.section?.minHeight}
        bg={node?.section?.bg}
        bgImage={node?.section?.bgImage}
        spaceT={node?.section?.spaceT || 8}
        spaceB={node?.section?.spaceB || 8}
        stackSpace={node?.section?.stackSpace || 5}
        id={node?.section?.htmlID?.current}
        vRhythm={node?.section?.disableVRhythm ? false : true}
        variant={node?.variant}
      />
    );
  },
);
