import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { Section } from '@lcifr/components/section';
import { CollectionType } from '@lcifr/components/collection';
import { Article } from './Article';
import { Button } from '@lcifr/components/button';
import { useCollection, useRawI18n } from '@lcifr/pages';

type NewsSectionProps = {
  width?: any;
  minHeight?: any;
  bg?: any;
  bgImage?: any;
  spaceT?: any;
  spaceB?: any;
  stackSpace?: any;
  id?: any;
  vRhythm?: any;
  content?: any;
  articleList: any;
  cutFirst: any;
};

export function NewsSection({
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
  articleList,
  cutFirst,
}: NewsSectionProps) {
  const [limit, setLimit] = React.useState(6);
  const i18n = useRawI18n();
  const buttonTextMore = i18n?.newsSection?.buttonTextMore;
  const buttonTextLess = i18n?.newsSection?.buttonTextLess;
  const articles = useCollection(articleList);

  if (cutFirst) {
    articles?.shift();
  }

  return articles ? (
    <Section
      width={width}
      minHeight={minHeight}
      bg={bg}
      bgImage={bgImage}
      spaceT={spaceT || 0}
      spaceB={spaceB || 8}
      stackSpace={0}
      id={id}
      vRhythm={vRhythm}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: ['column', 'column', 'row'],
          gap: ['8px', '8px', 40],
        }}
      >
        {articles?.map((article, i) =>
          i <= limit - 1 ? (
            <Article
              key={`article-${i}`}
              headline={(article as any)?.headline}
              image={(article as any)?.image}
              link={(article as any)?.slug}
              itemsLength={articles?.length}
            />
          ) : null,
        )}
      </Box>
      {articles?.length > 6 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 40 }}>
          <Button
            onClick={() => {
              limit === 6 ? setLimit(50) : setLimit(6);
            }}
          >
            {limit === 6 ? buttonTextMore : buttonTextLess}
          </Button>
        </Box>
      ) : null}
    </Section>
  ) : null;
}
