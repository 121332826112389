import { useRouter } from 'next/router';
import * as React from 'react';
import { useAsyncEffect } from 'use-async-effect';

type Countries = typeof import('i18n-iso-countries');

function getIsoLocaleFromLocale(locale: string) {
  if (locale === 'jp') return 'ja';
  return locale;
}

export function useGetCountryName() {
  const { locale } = useRouter();
  const [countries, setCountries] = React.useState<Countries>();
  const [registeredLocales, setRegisteredLocales] = React.useState<string[]>(
    [],
  );

  useAsyncEffect(
    async (isActive) => {
      try {
        if (!locale) return;
        const countries = (await import(`i18n-iso-countries`)).default;
        const countriesLocale = (
          await import(
            `i18n-iso-countries/langs/${getIsoLocaleFromLocale(locale)}.json`
          )
        ).default;
        countries.registerLocale(countriesLocale);
        setRegisteredLocales((locales) => [...locales, locale]);
        if (isActive()) setCountries(countries);
      } catch (e) {
        console.error('Error while loading country localization code:');
        console.error(e);
      }
    },
    [locale],
  );

  const getCountryName = React.useCallback(
    (countryCode: string | number) => {
      if (countries && locale && registeredLocales.includes(locale))
        return countries.getName(countryCode, getIsoLocaleFromLocale(locale));

      return countryCode;
    },
    [countries, locale, registeredLocales],
  );

  return { getCountryName, countries, registeredLocales };
}
