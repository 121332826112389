import { createType } from '@lcifr/portable';
import { HeroNews } from './HeroNews';

export const PortableHeroNewsType = createType('heroNews', ({ node }) => {
  return (
    <>
      <HeroNews
        articleList={node?.articleList}
        width={node?.section?.width}
        minHeight={node?.section?.minHeight}
        bg={node?.section?.bg}
        bgImage={node?.section?.bgImage}
        spaceT={node?.section?.spaceT || 0}
        spaceB={node?.section?.spaceB || 0}
        stackSpace={0}
        id={node?.section?.htmlID?.current}
        vRhythm={node?.section?.disableVRhythm ? false : true}
        isCompressed={node?.isCompressed ? true : false}
      />
      {/* <pre>{JSON.stringify(node, null, 2)}</pre> */}
    </>
  );
});
