import * as React from 'react';
import { sx } from '@lcifr/sx';
import { Heading } from '@lcifr/components/heading';
import { Box } from '@lcifr/components/box';
import { CollectionType } from '@lcifr/components/collection';
import { Image } from 'components/Image';
import { usePortableNodes } from '@lcifr/components/portable-nodes';
import { Stack } from '@lcifr/components/stack';
import { LinkStudio } from '@lcifr/components/link';
import { Anchor } from '@lcifr/components/anchor';
import { Text } from '@lcifr/components/text';
import Link from 'next/link';

export const Headline = sx(Heading)({});

type CertificateSectionProps = {
  certificateList: any;
  stackSpace: any;
  headline: any;
};

const getUrlFromId = (ref) => {
  if (!ref) return '';
  const [_file, id, extension] = ref.split('-');
  return `https://cdn.sanity.io/files/${process.env.NEXT_PUBLIC_STUDIO_PROJECT_ID}/${process.env.NEXT_PUBLIC_STUDIO_DATASET}/${id}.${extension}`;
};

export function CertificateSection({
  certificateList,
  stackSpace,
  headline,
}: CertificateSectionProps) {
  const PortableNodes = usePortableNodes();
  return (
    <Box
      sx={{
        display: 'grid',
        // https://stackoverflow.com/questions/55347359/why-is-css-grid-row-height-different-in-safari
        // safari fix ~DH
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gap: [40, 40, 80],
          gridTemplateColumns: ['1fr', '1fr', '1fr 2fr'],
        }}
      >
        <Box
          sx={{ height: '50%', display: 'flex', alignItems: 'center', pr: 8 }}
        >
          <Heading as="h1" variant="h2">
            {headline}
          </Heading>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gap: [20, 20, 40],
            gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr'],
          }}
        >
          <CollectionType node={certificateList}>
            {(certificates) => {
              return (
                <>
                  {certificates.map((certificate) => (
                    <Link
                      key={(certificate as any)?._id}
                      href={getUrlFromId(
                        (certificate as any)?.file?.asset?._ref,
                      )}
                      passHref
                      legacyBehavior
                    >
                      <Anchor>
                        <Box
                          sx={{
                            bg: 'light',
                            px: 4,
                            py: 4,
                            textAlign: 'center',
                            '&:hover': {
                              '> div:first-of-type': {
                                transform: 'scale(1.02)',
                              },
                            },
                          }}
                        >
                          <Image
                            image={(certificate as any)?.image}
                            alt={(certificate as any)?.name}
                            sx={{
                              position: 'relative',
                              paddingTop: '50%',
                              borderRadius: '100%',
                              transition: 'transform ease-out 0.2s',
                              img: {
                                objectFit: 'contain !important',
                              },
                              mb: 3,
                            }}
                          />
                          <Stack space={0}>
                            {(certificate as any)?.name && (
                              <Heading as="h1" variant="h5">
                                {(certificate as any)?.name}
                              </Heading>
                            )}
                            {(certificate as any)?.file &&
                              (certificate as any)?.linkText && (
                                <Text
                                  sx={{
                                    color: 'primary',
                                    fontWeight: 500,
                                    fontSize: '13px',
                                    letterSpacing: '0.2em',
                                    textTransform: 'uppercase',
                                  }}
                                >
                                  {(certificate as any)?.linkText}
                                </Text>
                              )}
                          </Stack>
                        </Box>
                      </Anchor>
                    </Link>
                  ))}
                </>
              );
            }}
          </CollectionType>
        </Box>
      </Box>
    </Box>
  );
}
