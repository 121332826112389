import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { Section } from '@lcifr/components/section';
import { CollectionType } from '@lcifr/components/collection';
import { Video } from './Video';
import { Button } from '@lcifr/components/button';
import { useRawI18n } from '@lcifr/pages';

type VideoSectionProps = {
  width?: any;
  minHeight?: any;
  bg?: any;
  bgImage?: any;
  spaceT?: any;
  spaceB?: any;
  stackSpace?: any;
  id?: any;
  vRhythm?: any;
  content?: any;
  videoList: any;
};

export function VideoSection({
  width,
  minHeight,
  bg,
  bgImage,
  spaceT,
  spaceB,
  stackSpace,
  id,
  vRhythm,
  videoList,
}: VideoSectionProps) {
  const [limit, setLimit] = React.useState(3);
  const i18n = useRawI18n();
  const buttonTextMore = i18n?.videoSection?.buttonTextMore;
  const buttonTextLess = i18n?.videoSection?.buttonTextLess;
  return videoList ? (
    <Section
      width={width}
      minHeight={minHeight}
      bg={bg}
      bgImage={bgImage}
      spaceT={spaceT || 0}
      spaceB={spaceB || 8}
      stackSpace={0}
      id={id}
      vRhythm={vRhythm}
    >
      <CollectionType node={videoList}>
        {(videos) => {
          return (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: ['column', 'column', 'row'],
                  gap: ['8px', '8px', 40],
                }}
              >
                {videos?.map((video, i) =>
                  i <= limit - 1 ? (
                    <Video
                      key={`video-${i}`}
                      headline={(video as any)?.headline}
                      videoId={(video as any)?.video?.asset?._ref}
                      itemsLength={videos?.length}
                    />
                  ) : null,
                )}
              </Box>
              {videos?.length > 3 ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', pt: 40 }}>
                  <Button
                    onClick={() => {
                      limit === 3 ? setLimit(50) : setLimit(3);
                    }}
                  >
                    {limit === 3 ? buttonTextMore : buttonTextLess}
                  </Button>
                </Box>
              ) : null}
            </>
          );
        }}
      </CollectionType>
    </Section>
  ) : null;
}
