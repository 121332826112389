import { createType } from '@lcifr/portable';
import { HeroSlider } from './HeroSlider';

export const PortableHeroSliderType = createType('heroSlider', ({ node }) => {
  return (
    <>
      <HeroSlider slides={node?.heroSlides} claim={node?.claim} />
      {/* <pre>{JSON.stringify(node, null, 2)}</pre> */}
    </>
  );
});
