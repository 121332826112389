import {
  FERMENTER_ACID_CONCENTRATIONS,
  FERMENTER_DEFAULT_ACID_CONCENTRATION,
  FERMENTER_DEFAULT_VINEGAR_TYPE,
  FERMENTER_NUTRITION_TYPE_LABEL,
  FERMENTER_VINEGAR_TYPES,
  FERMENTER_VINEGAR_TYPE_LABEL_KEY,
  FermenterAcidConcentration,
  FermenterVinegarType,
} from '@/utils/const';
import { useGetCountryName } from '@/utils/hooks/useGetCountryName';
import { Box } from '@lcifr/components/box';
import { Button } from '@lcifr/components/button';
import { CenterY } from '@lcifr/components/center';
import { Heading } from '@lcifr/components/heading';
import { Hidden } from '@lcifr/components/hidden';
import { Icon } from '@lcifr/components/icon';
import { usePortableNodes } from '@lcifr/components/portable-nodes';
import { Stack } from '@lcifr/components/stack';
import { Text } from '@lcifr/components/text';
import { useRawI18n } from '@lcifr/pages';
import { sx } from '@lcifr/sx';
import { alpha } from '@lcifr/theming';
import { Image } from 'components/Image';
import { Anchor } from 'components/SalespartnerTeaser';
import type { ResponseData } from 'pages/api/mail/fermenter';
import * as React from 'react';
import { formatAsPercent, formatAsNumber } from '@/utils/format';
import {
  FERMENTERS,
  FERMENTER_NUTRITION_TYPE,
  FERMENTER_TYPE,
} from '@/utils/const';
import { useRouter } from 'next/router';

// console.log(countries);

// TODO:
// RECAPTCHA --> https://prateeksurana.me/blog/integrating-recaptcha-with-next/

export const Ul = sx('ul')({
  listStyle: 'none',
  pt: 2,
  li: {
    fontSize: '15px',
    strong: {
      color: 'primary',
      fontWeight: 600,
    },
  },
});

export const Hr = sx('hr')({
  bg: alpha('secondary', 0.2),
  border: 0,
  height: '1px',
});

export const Label = sx('label')({
  fontSize: '13px',
  fontWeight: 600,
});

export const Input = sx('input')({
  px: 4,
  py: 3,
  bg: 'background',
  borderRadius: '12px',
  border: 0,
  fontSize: '15px',
  fontWeight: 300,
  fontFamily: 'body',
  width: '100%',
  boxSizing: 'border-box',
});

export const Select = sx('select')({
  px: 4,
  py: 3,
  userSelect: 'none',
  cursor: 'pointer',
  appearance: 'none',
  borderRadius: '12px',
  bg: 'background',
  border: 'none',
  fontSize: '15px',
  fontWeight: 300,
  fontFamily: 'body',
  color: '#0f1125',
  width: '100%',
  boxSizing: 'border-box',
});

export const Glow = sx(Box)({
  bg: 'transparent',
  position: 'absolute',
  left: '-5%',
  bottom: '-15%',
  height: '40%',
  width: '180%',
  background:
    'radial-gradient(50% 50% at 50% 50%, rgba(0, 71, 255, 1) 0%, rgba(111, 44, 255, 0) 100%)',
  transform: 'rotate(-10deg) translateY(0)',
  opacity: 0.4,
  transition: 'all 0.3s ease-out',
});

function calculate({
  vinegarType,
  fermenterVolume,
  acidConcentration,
}: {
  vinegarType: string;
  fermenterVolume: number;
  acidConcentration: number;
}): {
  fermenter: (typeof FERMENTERS)[number];
  l10: number;
  nutritionType: FERMENTER_NUTRITION_TYPE;
  nutritionNeed: number;
} {
  const l10 = ((fermenterVolume * acidConcentration) / 100) * 1000;

  const fermenter =
    FERMENTERS.find(({ value }) => value >= l10) ??
    FERMENTERS[FERMENTERS.length - 1];

  const nutritionType =
    vinegarType === 'alcohol14' || vinegarType === 'alcohol20'
      ? FERMENTER_NUTRITION_TYPE.complete
      : FERMENTER_NUTRITION_TYPE.wine;

  const nutritionNeed =
    (l10 / 1000) *
    (nutritionType === FERMENTER_NUTRITION_TYPE.complete ? 1.3 : 0.5);

  return {
    fermenter,
    l10,
    nutritionType,
    nutritionNeed,
  };
}

type FormFermenterProps = {};

export function FormFermenter({}: FormFermenterProps) {
  const { locale } = useRouter();
  const { getCountryName, countries } = useGetCountryName();
  const PortableNodes = usePortableNodes();
  const i18n = useRawI18n();
  const formFermenter = i18n?.formFermenter;

  // in
  const [vinegarType, setVinegarType] = React.useState<FermenterVinegarType>(
    FERMENTER_DEFAULT_VINEGAR_TYPE,
  );
  const [fermenterVolume, setFermenterVolume] = React.useState(0);
  const [acidConcentration, setAcidConcentration] =
    React.useState<FermenterAcidConcentration>(
      FERMENTER_DEFAULT_ACID_CONCENTRATION,
    );

  // form state
  const [formStep, setFormStep] = React.useState(0);

  const [inputName, setInputName] = React.useState('');
  const [inputCountry, setInputCountry] = React.useState('');
  const [inputCompany, setInputCompany] = React.useState('');
  const [inputPhone, setInputPhone] = React.useState('');
  const [inputEmail, setInputEmail] = React.useState('');
  const [honeypot, setHoneypot] = React.useState<string | undefined>();

  const [sendStatePending, setSendStatePending] = React.useState(false);
  const [sendStateError, setSendStateError] = React.useState<
    ResponseData['fields'] | boolean
  >(false);

  const { fermenter, l10, nutritionNeed, nutritionType } = React.useMemo(
    () =>
      calculate({
        vinegarType,
        fermenterVolume,
        acidConcentration,
      }),
    [fermenterVolume, acidConcentration, vinegarType],
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.reportValidity() === false) return;
    setFormStep(3);

    let data = {
      inputName,
      inputCountry,
      inputCompany,
      inputPhone,
      inputEmail,
      vinegarType,
      fermenterVolume,
      acidConcentration,
      fermenter,
      l10,
      nutritionType,
      nutritionNeed,
      honeypot,
    };
    setSendStatePending(true);
    const response = await fetch('/api/mail/fermenter', {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    setSendStatePending(false);
    if (response.ok) {
      setSendStateError(false);
    } else {
      const data = (await response.json()) as ResponseData;
      const isUserRecoverableError =
        data.fields &&
        Object.keys(data.fields).some((fieldName) =>
          [
            'inputName',
            'inputCountry',
            'inputCompany',
            'inputPhone',
            'inputEmail',
          ].includes(fieldName),
        );

      if (isUserRecoverableError) {
        setFormStep(2);
        setSendStateError(data.fields);
      } else {
        setSendStateError(true);
      }
    }
  };

  function handleStepZero(e) {
    e.preventDefault();
    if (e.target.reportValidity() === false) return;
    setFormStep(1);
  }

  function resetForm() {
    setInputName('');
    setInputCountry('');
    setInputCompany('');
    setInputPhone('');
    setInputEmail('');
    setVinegarType(FERMENTER_DEFAULT_VINEGAR_TYPE);
    setFermenterVolume(0);
    setAcidConcentration(FERMENTER_DEFAULT_ACID_CONCENTRATION);
    setFormStep(0);
    setSendStateError(false);
    setSendStatePending(false);
  }

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
          borderRadius: '12px',
          overflow: 'hidden',
          height: formStep === 0 ? 'auto' : 0,
          opacity: formStep === 0 ? 1 : 0,
          pointerEvents: formStep === 0 ? 'auto' : 'none',
          transform: formStep === 0 ? 'translateY(0)' : 'translateY(24px)',
          transition: 'transform 0.2s ease-out',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            background:
              'linear-gradient(180deg, rgba(15,17,37,0.1) 0%, rgba(15,17,37,0.03) 100%)',
            pt: '100%',
          }}
        >
          <Image
            image={(formFermenter as any)?.imageForm}
            alt=""
            sx={{
              position: 'absolute',
              top: '10%',
              left: '15%',
              height: '70%',
              width: '70%',
              img: { objectFit: 'contain !important' },
            }}
          />
          <Heading
            sx={{
              position: 'absolute',
              bottom: '10%',
              left: '50%',
              width: '80%',
              transform: 'translateX(-50%)',
              textAlign: 'center',
            }}
            variant="h4"
          >
            {(formFermenter as any)?.headlineForm}
          </Heading>
        </Box>
        <Box
          sx={{
            bg: 'secondary',
            color: 'text.inverted',
            p: ['50px', '50px', 8],
            position: 'relative',
          }}
        >
          <Glow />
          <CenterY>
            <form
              onSubmit={(e) => {
                handleStepZero(e);
              }}
            >
              <Stack space={4}>
                <Heading variant="h3">{formFermenter?.sublineForm}</Heading>
                <Stack space={2}>
                  <Label>{formFermenter?.labelVinegarType}</Label>
                  <Box sx={{ position: 'relative' }}>
                    <Select
                      value={vinegarType}
                      onChange={(e) => {
                        setVinegarType(e.target.value);
                      }}
                    >
                      {FERMENTER_VINEGAR_TYPES.map((vinegarType) => (
                        <option key={vinegarType} value={vinegarType}>
                          {
                            formFermenter?.[
                              FERMENTER_VINEGAR_TYPE_LABEL_KEY[vinegarType]
                            ]
                          }
                        </option>
                      ))}
                    </Select>
                    <Icon
                      name="chev-down"
                      sx={{
                        position: 'absolute',
                        right: 24,
                        pointerEvents: 'none',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    />
                  </Box>
                </Stack>
                <Stack space={2}>
                  <Label>{formFermenter?.labelAmountLiters}</Label>
                  <Input
                    type="number"
                    value={fermenterVolume}
                    min={1}
                    required
                    onChange={(e) => {
                      setFermenterVolume(Number.parseInt(e.target.value, 10));
                    }}
                  />
                </Stack>
                <Stack space={2}>
                  <Label>{formFermenter?.labelAcidConcentration}</Label>
                  <Stack space={2}>
                    <Box sx={{ position: 'relative' }}>
                      <Select
                        onChange={(e) => {
                          setAcidConcentration(
                            Number.parseFloat(
                              e.target.value,
                            ) as FermenterAcidConcentration,
                          );
                        }}
                        value={acidConcentration}
                      >
                        {FERMENTER_ACID_CONCENTRATIONS.map((value) => (
                          <option key={value} value={value}>
                            {formatAsPercent(value, locale)}
                          </option>
                        ))}
                      </Select>
                      <Icon
                        name="chev-down"
                        sx={{
                          position: 'absolute',
                          right: 24,
                          pointerEvents: 'none',
                          top: '50%',
                          transform: 'translateY(-50%)',
                        }}
                      />
                    </Box>
                  </Stack>
                </Stack>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button type="submit">
                    {formFermenter?.buttonFindFermenter}
                  </Button>
                </Box>
              </Stack>
            </form>
          </CenterY>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '1fr 2fr'],
          borderRadius: '12px',
          overflow: 'hidden',
          height: formStep === 1 ? 'auto' : 0,
          opacity: formStep === 1 ? 1 : 0,
          pointerEvents: formStep === 1 ? 'auto' : 'none',
          transform: formStep === 1 ? 'translateY(0)' : 'translateY(24px)',
          transition: 'transform 0.2s ease-out',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            background:
              'linear-gradient(180deg, rgba(15,17,37,0.1) 0%, rgba(15,17,37,0.03) 100%)',
            pt: '100%',
          }}
        >
          <Image
            image={
              fermenter.type === FERMENTER_TYPE.industrial
                ? formFermenter?.imageFermenterIndustrial
                : formFermenter?.imageFermenterSpecial
            }
            alt=""
            sx={{
              position: 'absolute',
              top: '10%',
              left: '5%',
              height: '70%',
              width: '90%',
              img: { objectFit: 'contain !important' },
            }}
          />
          <Heading
            sx={{
              position: 'absolute',
              bottom: '8%',
              left: '50%',
              width: '80%',
              transform: 'translateX(-50%)',
              textAlign: 'center',
            }}
            variant="h4"
          >
            {fermenter.type === FERMENTER_TYPE.industrial
              ? formFermenter?.fermenterIndustrial
              : formFermenter?.fermenterSpecial}{' '}
            {fermenter.name}
          </Heading>
        </Box>
        <Box
          sx={{
            bg: '#F8F7FD',
            color: 'text',
            p: ['50px', '50px', 8],
            position: 'relative',
          }}
        >
          {/* <Glow /> */}
          <CenterY>
            <Stack space={6}>
              <Stack space={2}>
                <Button
                  variant="strippedPrimary"
                  sx={{
                    fontSize: '16px !important',
                    textTransform: 'uppercase',
                    letterSpacing: '3px',
                  }}
                  onClick={() => setFormStep(0)}
                >
                  <Icon name="chev-left" sx={{ mr: 2, color: 'primary' }} />
                  {formFermenter?.buttonBackStepOne}
                </Button>

                <Heading variant="h3">{formFermenter?.headlineStepOne}</Heading>
              </Stack>
              <Stack space={[2]}>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
                    gap: ['18px', '18px', 40],
                  }}
                >
                  <Stack space={3}>
                    <Stack space={1}>
                      <Text>{formFermenter?.labelAdvisedFermenter}</Text>
                      <Text sx={{ color: 'primary', fontWeight: 600 }}>
                        {fermenter.name} (
                        {formatAsNumber(fermenter.value, locale)} l)
                      </Text>
                    </Stack>
                    <Hr />
                    <Stack space={1}>
                      <Text>{formFermenter?.labelExpectedOutput}</Text>
                      <Text sx={{ color: 'primary', fontWeight: 600 }}>
                        {formatAsNumber(l10, locale, {
                          maximumFractionDigits: 0,
                        })}{' '}
                        l
                      </Text>
                    </Stack>
                  </Stack>
                  <Hidden above="laptop">
                    <Hr />
                  </Hidden>
                  <Stack space={3}>
                    <Stack space={1}>
                      <Text>{formFermenter?.labelNutritionType}</Text>
                      <Text sx={{ color: 'primary', fontWeight: 600 }}>
                        {FERMENTER_NUTRITION_TYPE_LABEL[nutritionType]}
                      </Text>
                    </Stack>
                    <Hr />
                    <Stack space={1}>
                      <Text>{formFermenter?.labelNutritionAmount}</Text>
                      <Text sx={{ color: 'primary', fontWeight: 600 }}>
                        {formatAsNumber(nutritionNeed, locale)} kg
                      </Text>
                    </Stack>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button onClick={() => setFormStep(2)}>
                    {formFermenter?.buttonGetQuote}
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </CenterY>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '1fr 2fr'],
          borderRadius: '12px',
          overflow: 'hidden',
          height: formStep === 2 ? 'auto' : 0,
          opacity: formStep === 2 ? 1 : 0,
          pointerEvents: formStep === 2 ? 'auto' : 'none',
          transform: formStep === 2 ? 'translateY(0)' : 'translateY(24px)',
          transition: 'transform 0.2s ease-out',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            background:
              'linear-gradient(180deg, rgba(15,17,37,0.1) 0%, rgba(15,17,37,0.03) 100%)',
            pt: '100%',
          }}
        >
          <Image
            image={(formFermenter as any)?.imageForm}
            alt=""
            sx={{
              position: 'absolute',
              top: '10%',
              left: '15%',
              height: '70%',
              width: '70%',
              img: { objectFit: 'contain !important' },
            }}
          />
          <Heading
            sx={{
              position: 'absolute',
              bottom: '10%',
              left: '50%',
              width: '80%',
              transform: 'translateX(-50%)',
              textAlign: 'center',
            }}
            variant="h4"
          >
            {fermenter.type === FERMENTER_TYPE.industrial
              ? formFermenter?.fermenterIndustrial
              : formFermenter?.fermenterSpecial}{' '}
            {fermenter.name}
          </Heading>
        </Box>
        <Box
          sx={{
            bg: '#F8F7FD',
            color: 'text',
            p: ['50px', '50px', 8],
            position: 'relative',
          }}
        >
          {/* <Glow /> */}
          <CenterY>
            <form
              method="get"
              id="formFermenter"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <Stack space={6}>
                <Stack space={2}>
                  <Button
                    variant="strippedPrimary"
                    sx={{
                      fontSize: '16px !important',
                      textTransform: 'uppercase',
                      letterSpacing: '3px',
                    }}
                    onClick={() => setFormStep(1)}
                  >
                    <Icon name="chev-left" sx={{ mr: 2, color: 'primary' }} />
                    {formFermenter?.buttonBackStepTwo}
                  </Button>

                  <Heading variant="h3">
                    {formFermenter?.headlineStepTwo}
                  </Heading>
                </Stack>
                <Stack space={4}>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
                      gap: ['18px', '18px', 40],
                    }}
                  >
                    <Stack
                      space={3}
                      sx={{ gridColumn: ['auto', null, 'span 2'] }}
                    >
                      <Stack space={2}>
                        <Label htmlFor="name">
                          {formFermenter?.labelName}
                          {(sendStateError as ResponseData['fields'])?.inputName
                            ?._errors ? (
                            <>
                              {' — '}
                              <Text variant="error">
                                {
                                  (sendStateError as ResponseData['fields'])
                                    ?.inputName?._errors?.[0]
                                }
                              </Text>
                            </>
                          ) : null}
                        </Label>
                        <Input
                          name="name"
                          type="text"
                          value={inputName}
                          placeholder={formFermenter?.placeholderName}
                          required
                          onChange={(e) => {
                            setInputName(e.target.value);
                          }}
                        />
                      </Stack>
                    </Stack>
                    <Stack space={3}>
                      <Stack space={2}>
                        <Label htmlFor="country">
                          {formFermenter?.labelCountry}
                          {(sendStateError as ResponseData['fields'])
                            ?.inputCountry?._errors ? (
                            <>
                              {' — '}
                              <Text variant="error">
                                {
                                  (sendStateError as ResponseData['fields'])
                                    ?.inputCountry?._errors?.[0]
                                }
                              </Text>
                            </>
                          ) : null}
                        </Label>
                        <Select
                          name="country"
                          value={inputCountry}
                          required
                          onChange={(e) => {
                            setInputCountry(e.target.value);
                          }}
                        >
                          <option value="" disabled>
                            {formFermenter?.placeholderCountry}
                          </option>
                          {Object.keys(countries?.getAlpha2Codes() ?? {}).map(
                            (code) => (
                              <option key={code} value={code}>
                                {getCountryName(code)}
                              </option>
                            ),
                          )}
                        </Select>
                      </Stack>
                      <Stack space={2}>
                        <Label htmlFor="company">
                          {formFermenter?.labelCompany}
                          {(sendStateError as ResponseData['fields'])
                            ?.inputCompany?._errors ? (
                            <>
                              {' — '}
                              <Text variant="error">
                                {
                                  (sendStateError as ResponseData['fields'])
                                    ?.inputCompany?._errors?.[0]
                                }
                              </Text>
                            </>
                          ) : null}
                        </Label>
                        <Input
                          name="company"
                          type="text"
                          value={inputCompany}
                          placeholder={formFermenter?.placeholderCompany}
                          required
                          onChange={(e) => {
                            setInputCompany(e.target.value);
                          }}
                        />
                      </Stack>
                    </Stack>
                    <Stack space={3}>
                      <Stack space={2}>
                        <Label htmlFor="phone">
                          {formFermenter?.labelPhone}
                          {(sendStateError as ResponseData['fields'])
                            ?.inputPhone?._errors ? (
                            <>
                              {' — '}
                              <Text variant="error">
                                {
                                  (sendStateError as ResponseData['fields'])
                                    ?.inputPhone?._errors?.[0]
                                }
                              </Text>
                            </>
                          ) : null}
                        </Label>
                        <Input
                          name="phone"
                          type="tel"
                          value={inputPhone}
                          placeholder={formFermenter?.placeholderPhone}
                          required
                          onChange={(e) => {
                            setInputPhone(e.target.value);
                          }}
                        />
                      </Stack>
                      <Stack space={2}>
                        <Label htmlFor="email">
                          {formFermenter?.labelEmail}
                          {(sendStateError as ResponseData['fields'])
                            ?.inputEmail?._errors ? (
                            <>
                              {' — '}
                              <Text variant="error">
                                {
                                  (sendStateError as ResponseData['fields'])
                                    ?.inputEmail?._errors?.[0]
                                }
                              </Text>
                            </>
                          ) : null}
                        </Label>
                        <Input
                          name="email"
                          type="email"
                          value={inputEmail}
                          placeholder={formFermenter?.placeholderEmail}
                          required
                          onChange={(e) => {
                            setInputEmail(e.target.value);
                          }}
                        />
                      </Stack>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Label>{formFermenter?.labelEquipment}</Label>
                      <Ul>
                        <li>
                          <strong>{fermenter.name}</strong> –{' '}
                          <strong>{formatAsNumber(l10, locale)} l</strong>
                        </li>
                        <li>
                          <strong>
                            {FERMENTER_NUTRITION_TYPE_LABEL[nutritionType]}
                          </strong>{' '}
                          –{' '}
                          <strong>
                            {formatAsNumber(nutritionNeed, locale)} kg
                          </strong>
                        </li>
                      </Ul>
                    </Box>

                    {/* honeypot */}
                    <input
                      type="text"
                      name="inputMessage"
                      value={honeypot}
                      style={{ display: 'none' }}
                      tabIndex={-1}
                      autoComplete="false"
                      onChange={(e) => {
                        setHoneypot(e.target.value);
                      }}
                    />

                    <Button type="submit" form="formFermenter">
                      {formFermenter?.buttonSubmitRequest}
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            </form>
          </CenterY>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          borderRadius: '12px',
          overflow: 'hidden',
          height: formStep === 3 ? 'auto' : 0,
          opacity: formStep === 3 ? 1 : 0,
          pointerEvents: formStep === 3 ? 'auto' : 'none',
          transform: formStep === 3 ? 'translateY(0)' : 'translateY(24px)',
          transition: 'transform 0.2s ease-out',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            background:
              'linear-gradient(180deg, rgba(15,17,37,0.1) 0%, rgba(15,17,37,0.03) 100%)',
            pt: '40%',
          }}
        >
          <Image
            image={(formFermenter as any)?.imageForm}
            alt=""
            sx={{
              position: 'absolute',
              top: '5%',
              left: '15%',
              height: '90%',
              width: '70%',
              opacity: 0.2,
              img: { objectFit: 'contain !important' },
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '60%',
              transform: 'translate(-50%,-50%)',
              textAlign: 'center',
            }}
          >
            {sendStatePending ? (
              <PortableNodes blocks={(formFermenter as any)?.contentPending}>
                {(children) => <Stack space={2}>{children}</Stack>}
              </PortableNodes>
            ) : sendStateError === false ? (
              <PortableNodes blocks={(formFermenter as any)?.contentSuccess}>
                {(children) => <Stack space={2}>{children}</Stack>}
              </PortableNodes>
            ) : (
              <PortableNodes blocks={(formFermenter as any)?.contentError}>
                {(children) => <Stack space={2}>{children}</Stack>}
              </PortableNodes>
            )}
            {sendStatePending ? null : (
              <Anchor
                sx={{
                  color: 'primary',
                  mt: 2,
                  fontSize: '16px !important',
                  textTransform: 'uppercase',
                  letterSpacing: '3px',
                  fontWeight: 600,
                  cursor: 'pointer',
                }}
                onClick={() => resetForm()}
              >
                {(formFermenter as any)?.linkResetForm}
              </Anchor>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
