import * as React from 'react';
import { Text } from '@lcifr/components/text';

export function Bleach({ children }) {
  return (
    <Text as="span" variant="bleach">
      {children}
    </Text>
  );
}
