import * as React from 'react';
import { sx } from '@lcifr/sx';
import { Heading } from '@lcifr/components/heading';
import { Box } from '@lcifr/components/box';
import { CollectionType } from '@lcifr/components/collection';
import { Image } from 'components/Image';
import { alpha } from '@lcifr/theming';
import { Icon } from '@lcifr/components/icon';
import { usePortableNodes } from '@lcifr/components/portable-nodes';
import { Stack } from '@lcifr/components/stack';

export const Headline = sx(Heading)({});

type PartnerProps = {
  partnerList: any;
  stackSpace: any;
};

export function Partner({ partnerList, stackSpace }: PartnerProps) {
  const [activeItem, setActiveItem] = React.useState(0);
  const PortableNodes = usePortableNodes();
  return (
    <Box
      sx={{
        display: 'grid',
        gap: [40, 40, 80],
        gridTemplateColumns: ['1fr', '1fr', '1fr 2fr'],
        gridTemplateAreas: [
          `"${['imagelist', 'textlist'].join('" "')}"`,
          `"${['imagelist', 'textlist'].join('" "')}"`,
          `"${['textlist imagelist imagelist'].join('" "')}"`,
        ],
      }}
    >
      <CollectionType node={partnerList}>
        {(partners) => (
          <Box
            sx={{
              gridArea: 'textlist',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {partners.map((partner, i) => (
              <Box
                sx={{
                  display: 'flex',
                  borderBottom: '1px solid',
                  borderColor: alpha('secondary', 0.1),
                  alignItems: 'center',
                  cursor: 'pointer',
                  userSelect: 'none',
                  pb: [2, 2, 3],
                  pt: [2, 2, 3],
                  '&:last-of-type': {
                    pb: 0,
                    borderBottom: 0,
                  },
                  '&:first-of-type': {
                    pt: 0,
                  },
                }}
                key={(partner as any)?._id}
                onClick={() => setActiveItem(i)}
              >
                <Icon
                  sx={{
                    display: activeItem === i ? 'flex' : 'none',
                    width: [48, 48, 64],
                    height: [48, 48, 64],
                    borderRadius: '100%',
                    bg: 'primary',
                    flexShrink: 0,
                    color: 'text.inverted',
                    alignItems: 'center',
                    justifyContent: 'center',
                    svg: {
                      width: 24,
                      height: 24,
                    },
                    mr: 3,
                  }}
                  name="chev-right"
                />
                <Image
                  image={(partner as any)?.portrait}
                  alt={(partner as any)?.name}
                  sx={{
                    display: activeItem === i ? 'none' : 'block',
                    position: 'relative',
                    width: [48, 48, 64],
                    height: [48, 48, 64],
                    borderRadius: '100%',
                    flexShrink: 0,
                    mr: 3,
                    overflow: 'hidden',
                  }}
                />

                <Heading
                  as="h4"
                  variant="h4"
                  sx={{
                    color: activeItem === i ? 'text' : alpha('secondary', 0.3),
                  }}
                >
                  {(partner as any)?.name}
                </Heading>
              </Box>
            ))}
          </Box>
        )}
      </CollectionType>
      <CollectionType node={partnerList}>
        {(partners) => (
          <>
            {partners.map((partner, i) => (
              <Box
                key={(partner as any)?._id}
                sx={{
                  gridArea: 'imagelist',
                  display: i === activeItem ? 'block' : 'none',
                }}
              >
                <Image
                  image={(partner as any)?.image}
                  alt={(partner as any)?.name}
                  sx={{
                    position: 'relative',
                    paddingTop: '50%',
                    mb: stackSpace,
                  }}
                />
                {(partner as any)?.content?.length && (
                  <PortableNodes blocks={(partner as any)?.content}>
                    {(children) => <Stack space={stackSpace}>{children}</Stack>}
                  </PortableNodes>
                )}
              </Box>
            ))}
          </>
        )}
      </CollectionType>
    </Box>
  );
}
