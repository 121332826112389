import * as portable from '@lcifr/portable';
import { ServiceSection } from './ServiceSection';
import { Section } from '@lcifr/components/section';

export const PortableServiceSectionType = portable.createType(
  'serviceSection',
  ({ node, context }) => (
    <Section
      width={node?.section?.width || context?.defaultWrapWidth}
      minHeight={node?.section?.minHeight}
      bg={node?.section?.bg}
      bgImage={node?.section?.bgImage}
      spaceT={node?.section?.spaceT || 8}
      spaceB={node?.section?.spaceB || 8}
      stackSpace={0}
      id={node?.section?.htmlID?.current}
      vRhythm={node?.section?.disableVRhythm ? false : true}
    >
      <ServiceSection
        serviceList={node?.serviceList}
        stackSpace={node?.stackSpace || 3}
        image={node?.image}
        headline={node?.headline}
      />
    </Section>
  ),
);
